import React, { useState } from 'react';

function ShortenedText({ text, maxLines = 2,  }) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    const textClasses = `overflow-hidden whitespace-pre-line ${expanded ? 'h-auto' : `h-14`}`;

    return (
        <>
            <div>
                <p className={textClasses}>{text}</p>
                {expanded ? (
                    <button
                        onClick={toggleExpanded}
                        className="text-primary-500 font-bold hover:underline focus:outline-none"
                    >
                        Read less
                    </button>
                ) : (
                    <button
                        onClick={toggleExpanded}
                        className="text-primary-500 font-bold hover:underline focus:outline-none"
                    >
                        ...Read more
                    </button>
                )}
            </div>
        </>
    )
}

export default ShortenedText