const CtaSection = () => {
    return (
        <section className="py-24">
            <div className="max-w-4xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5">
                <div className="bg-gradient-to-br from-primary-500 to-gray-500 rounded-lg p-8 md:p-10 py-14 lg:p-14 flex flex-col md:flex-row items-center justify-center text-center md:text-left md:justify-start md:items-start gap-8">
                    <div className="md:w-1/2">
                        <h1 className="text-xl lg:text-xl md:text-xl xl:text-3xl text-white font-display font-bold leading-tight">
                            Are you an event organizer?
                        </h1>
                    </div>
                    <div className="md:flex-1 space-y-8">
                        <div className="flex justify-center md:justify-start">
                            <a href="/contact" className="h-12 px-5 rounded-md flex items-center bg-primary-500 text-white">
                                Get In Touch
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    }
    export default CtaSection