import { useState, useEffect } from "react";
import PageSection from "../components/Misc/PageSection";
import { Image, Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
function Gallery() {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchImages = async () => {
            setIsLoading(true)
            try {
                let request = await fetch(`${BASE_URL}/gallery`);
                if (request.ok) {
                    const response = await request.json();
                    setImages(response.data)
                }
            } catch (err) {
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }
        fetchImages();
    }, [])

    return (
        <>
        <DynamicPageHeadContent title={"Past Events Gallery"} />
            <PageSection heading={"Past Events Gallery"} subheading={"Walk down memory lane and catch all the buzz that went down in the industry in Ghana."} />
            <div className='relative px-3'>
                <div className='max-w-screen-xl mx-auto py-8 lg:py-20'>
                    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                    {isLoading ? (
                       <Spin indicator={ <LoadingOutlined style={{ fontSize: 24, marginLeft: 5}} spin /> } />
                        ) : (images.length > 0 ? (
                            <Image.PreviewGroup items={images.image} >
                                <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
                                {images.map((img, i) => (
                                    <Image key={i} src={img.image} className="h-auto max-w-full rounded-lg" />
                                ))}
                                </div>
                            </Image.PreviewGroup>

                        ) : (
                            <section className="max-w-lg px-2 py-20 lg:px-4 lg:py-32 mx-auto space-y-1 text-center">
                                <Empty description={"No Images Available Now"} />
                            </section>
                        )
                    )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery