import { Outlet, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react';
import NavbarDefault from "../components/Navbar";
import FooterBlock from "../components/FooterBlock";

const PageLayout = () => {
    const { pathname } = useLocation()
       useLayoutEffect(() => {
          window.scrollTo(0, 0)
       }, [pathname])
    return (
        <>
            <div className="scroll-smooth bg-white">
                <NavbarDefault />
                    <Outlet />
                <FooterBlock />
            </div>
        </>
    )
}

export default PageLayout;