import PageSection from "../components/Misc/PageSection";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

const Privacy = () => {
    return (
        <>
        <DynamicPageHeadContent title={"Privacy Policy"} />
            <PageSection heading={"Privacy Policy"} subheading={"Last Updated as of October 17, 2023"} sHeight={60} mHeight={60} lHeight={60}/>
            <section>
                <div className="flex flex-col items-center justify-center px-5 md:px-10">
                <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:pb-20">
                    <div className="text-lg  text-gray-800">
                        <p className="mt-2 leading-loose">
                            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                            information when You use the Service and tells You about Your privacy rights and how the law protects You.
                        </p>
                        <p className="mt-2 leading-loose">
                            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                            collection and use of information in accordance with this Privacy Policy.
                        </p>

                        <h1 className="text-3xl font-bold mt-10"> Interpretation and Definitions</h1>
                        <h2 className="text-2xl font-bold mt-8">Interpretation</h2>
                        <p className="mt-2 leading-loose">
                            The words of which the initial letter is capitalized have meanings defined under the following conditions.
                        </p>
                        <p className="mt-2 leading-loose">
                            The following definitions shall have the same meaning regardless of whether they appear in singular or in
                            plural.
                        </p>
                        <h2 className="text-2xl font-bold mt-8">Definitions</h2>
                        <p className="mt-2 leading-loose">For the purposes of this Privacy Policy:</p>
                    </div>
                </div>
                </div>
            </section>
        </>
    );
}
export default Privacy;
