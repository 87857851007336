import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { notification, Empty, Checkbox, DatePicker } from 'antd';
import Spinner from "../components/Spinner";
import EventCard from "../components/EventCard";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";
import dayjs from 'dayjs';
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const thisWeekend = `${dayjs().weekday(6).format("YYYY-MM-DD")}:${dayjs().weekday(7).format("YYYY-MM-DD")}`
function Search() {
  const [searchTerm, setSearchTerm] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("q")
  const initialValues = { category: [], price: null, date: null, location: null };
  const [filters, setFilters] = useState(initialValues);
  const [customDate, setCustomDate] = useState("")


  const fetchEvents = async () => {
    setIsLoading(true)
    try {
      let request = await fetch(`${BASE_URL}/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: searchTerm === "" ? query : searchTerm , filters : filters}),
      });
      if (request.ok) {
        const response = await request.json();
        setEvents(response.data)
      }
    } catch (err) {
      notification.error({
        message: err.message,
      });
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    setSearchTerm(query)
    fetchEvents();
  }, [query])

  const handleSearch = (e) => {
    e.preventDefault()
    fetchEvents();
  }

  const handleFiltersChange = (e) => {
    let { name, value } = e.target;
    setFilters({ ...filters, [name]: value })
  }

  const options = [
    {
      label: 'Nightlife & Parties',
      value: 'Nightlife & Parties',
    },
    {
      label: 'Festival & Music',
      value: 'Festival & Music',
    },
    {
      label: 'Conference/Seminar',
      value: 'Conference/Seminar',
    },
    {
      label: 'Arts & Performances',
      value: 'Arts & Performances',
    },
    {
      label: 'Dinner',
      value: 'Dinner',
    },
  ];

  const onCategoryChange = (checkedValues) => {
    setFilters({ ...filters, "category": checkedValues })
  };

  const onDateChange = (date, dateString) => {
    setFilters({ ...filters, "date": dateString })
    setCustomDate(dateString)
  };


  return (
    <>
      <DynamicPageHeadContent title={"Events"} />
      <section>
        <div className="mx-auto w-full px-5 py-16 md:px-10 md:py-24">
          <div className="flex flex-col gap-12">
            <div className="grid gap-10 md:gap-12 lg:grid-cols-[max-content_1fr]">
              <div className="mb-4 max-w-none lg:max-w-sm">
                <form name="wf-form-Filter-2" className="flex-col gap-6" onSubmit={handleSearch}>
                  <div className="mb-6 flex items-center justify-between py-4 [border-bottom:1px_solid_rgb(217,_217,_217)]">
                    <h5 className="text-xl font-bold">Filters</h5>
                    <a href="#" onClick={() => { setFilters(initialValues)}} className="text-sm cursor-pointer">
                      <p>Clear all</p>
                    </a>
                  </div>

                  <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="mb-10 block h-9 min-h-[44px] w-full rounded-md border border-solid border-[#cccccc] bg-[#f2f2f7] bg-[16px_center] bg-no-repeat py-3 pl-11 pr-4 text-sm font-bold text-[#333333] [background-size:18px] [border-bottom:1px_solid_rgb(215,_215,_221)]" placeholder="Search" style={{ backgroundImage: 'url("https://assets.website-files.com/6458c625291a94a195e6cf3a/64b7a3a33cd5dc368f46daaa_MagnifyingGlass.svg")' }} />
                  <div className="flex flex-col gap-6">
                  <div className="flex cursor-pointer items-center justify-between py-4 [border-top:1px_solid_rgba(0,_0,_0,_0)] md:py-0">
                    <p className="font-semibold">Categories</p>
                    <span onClick={() => { setFilters({ ...filters, "category" : []})}} className="inline-block text-sm text-black">
                        <p>Clear</p>
                      </span>
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                    <Checkbox.Group options={options} onChange={onCategoryChange} checked={false} className="flex gap-3 rounded-md bg-[#f2f2f7] p-3 font-semibold" />
                    </div>
                  </div>

                  {/* Ratings  */}
                  {/* <div className="flex flex-col gap-6">
                    <p className="font-semibold">Rating</p>
                    <div className="flex flex-wrap gap-2 lg:justify-between">
                      <div className="flex h-9 w-14 cursor-pointer items-center justify-center rounded-md border border-solid border-[#cccccc] bg-[#f2f2f7] text-sm font-semibold">
                        <span>1</span>
                      </div>
                      <div className="flex h-9 w-14 cursor-pointer items-center justify-center rounded-md border border-solid border-[#cccccc] bg-black text-sm font-semibold text-white">
                        <span>2</span>
                      </div>
                      <div className="flex h-9 w-14 cursor-pointer items-center justify-center rounded-md border border-solid border-[#cccccc] bg-[#f2f2f7] text-sm font-semibold">
                        <span>3</span>
                      </div>
                      <div className="flex h-9 w-14 cursor-pointer items-center justify-center rounded-md border border-solid border-[#cccccc] bg-[#f2f2f7] text-sm font-semibold">
                        <span>4</span>
                      </div>
                      <div className="flex h-9 w-14 cursor-pointer items-center justify-center rounded-md border border-solid border-[#cccccc] bg-[#f2f2f7] text-sm font-semibold">
                        <span>5</span>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="mb-6 mt-6 h-px w-full bg-[#d9d9d9]"></div>
                  <div className="flex flex-col gap-6">
                    <div className="flex cursor-pointer items-center justify-between py-4 [border-top:1px_solid_rgba(0,_0,_0,_0)] md:py-0">
                      <p className="font-semibold">Price</p>
                      <span  onClick={() => { setFilters({ ...filters, "price" : null})}} className="inline-block text-sm text-black">
                        <p>Clear</p>
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="flex items-center text-sm font-medium">
                        <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="price" value="free" checked={filters.price === "free"} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">Free</span>
                      </label>
                      <label className="flex items-center text-sm font-medium">
                        <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="price" value="paid" checked={filters.price === "paid"} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">Paid</span>
                      </label>
                    </div>
                  </div> */}

                  <div className="mb-6 mt-6 h-px w-full bg-[#d9d9d9]"></div>
                  <div className="flex flex-col gap-6">
                    <div className="flex cursor-pointer items-center justify-between py-4 [border-top:1px_solid_rgba(0,_0,_0,_0)] md:py-0">
                      <p className="font-semibold">Date</p>
                      <span onClick={() => { setFilters({ ...filters, "date" : null})}} className="inline-block text-sm text-black">
                        <p>Clear</p>
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="flex items-center font-medium">
                        <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="date" value={dayjs().format("YYYY-MM-DD")} checked={filters.date === dayjs().format("YYYY-MM-DD")} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">Today</span>
                      </label>
                      <label className="flex items-center font-medium">
                        <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="date" value={dayjs().add(1, 'day').format("YY-MM-DD")} checked={filters.date === dayjs().add(1, 'day').format("YY-MM-DD")} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">Tomorrow</span>
                      </label>
                      <label className="flex items-center font-medium">
                        <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="date" value={thisWeekend} checked={filters.date === thisWeekend} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">This Weekend</span>
                      </label>
                      <label className="flex items-center font-medium">
                      <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="date" value={customDate} checked={filters.date === customDate} onChange={handleFiltersChange} />
                      <span className="inline-block cursor-pointer"><DatePicker onChange={onDateChange} format={"YYYY-MM-DD"} minDate={dayjs()}/></span>
                      </label>
                    </div>
                  </div>

                  <div className="mb-6 mt-6 h-px w-full bg-[#d9d9d9]"></div>
                  <div className="flex flex-col gap-6">
                    <div className="flex cursor-pointer items-center justify-between py-4 [border-top:1px_solid_rgba(0,_0,_0,_0)] md:py-0">
                      <p className="font-semibold">Location</p>
                      <span href="#" onClick={() => { setFilters({ ...filters, "location" : null})}} className="inline-block text-sm text-black">
                        <p>Clear</p>
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="flex items-center text-sm font-medium">
                        <input className="mr-3 h-5 w-5 cursor-pointer rounded-sm border border-solid bg-[#f2f2f7]" type="radio" name="location" value="online" checked={filters.location === "online"} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">Online</span>
                      </label>
                      <label className="flex items-center text-sm font-medium">
                      <input className="mr-3 h-5 w-5 cursor-pointer rounded-none border border-solid bg-[#f2f2f7]" type="radio" name="location"  value="offline" checked={filters.location === "offline"} onChange={handleFiltersChange} />
                        <span className="inline-block cursor-pointer" htmlFor="Filter-One-Option-1">Offline</span>
                      </label>
                    </div>
                  </div>
                  <div className="text-center content-center my-5">
                          <button type="submit" className="bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 my-2 focus:outline focus:outline-none transition duration-300 w-full py-2 md:py-3 flex justify-center items-center rounded-md text-center font-semibold">Search</button>
                      </div>
                </form>
              </div>
              <div className="w-full [border-left:1px_solid_rgb(217,_217,_217)]">
                <div className="">
                  {isLoading ? (
                    <Spinner />
                  ) : (events.length !== 0 ? (
                    <section id="allEvents"
                      className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-8 mt-5 mb-5">
                      {events.map((event, index) => (
                        <EventCard
                          index={index}
                          slug={event.slug}
                          image={event.image}
                          name={event.name}
                          date={event.date}
                          venue={event.venue}
                          code={event.ussdCode}
                        />
                      ))}

                    </section>

                  ) : (
                    <section className="max-w-lg px-2 py-5 lg:px-4 lg:py-20 mx-auto space-y-1 text-center">
                      <Empty description={"No Results"} />
                      <br />
                      <p className="text-gray-600">
                        <a href="/gallery" className="mt-5 inline-block items-center rounded-md bg-primary-500 px-6 py-2 text-center font-semibold text-white lg:mr-8">See Past Events Gallery</a>
                      </p>
                    </section>
                  )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Search