import { useState, useEffect } from "react";
import { Carousel } from 'antd';
import PageSection from "./Misc/PageSection"


let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const Banners = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
        setIsLoading(true)

        try {
            const request = await fetch(`${BASE_URL}/banners`);
            if (request.ok) {
                const res = await request.json();
                if (res.data && res.data.length > 0) {
                  setBanners(res.data)
                }
            }
        } catch (err) {
            // setError(err)
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    fetchBanners();
}, [])

  return (
      <>
      <div className='mt-16'>
        <Carousel autoplay>
          { isLoading ? ( 
          <PageSection subheading={"Your Gateway to Live Shows! Our easy-to-use ticketing platform makes it simple for individuals to book tickets quickly."} heading={"Your Premier ticketing service"}/>
        ) : (
            banners.length > 0 ? 
              banners.map((banner, index) => (
                <div className="relative h-[22rem] lg:h-[42rem]" id={index} key={index}>
                  <img src={banner.image} alt={index} className="w-full h-[22rem] lg:h-[42rem] object-cover" />
                  <div className="absolute inset-0 bg-black opacity-70" />
                  <div className="absolute inset-0 mx-auto lg:max-w-7xl w-full px-5 sm:px-10 md:px-12 lg:px-5 items-center mt-10 lg:mt-32">
                    <div className="mt-20 md:mt-0 lg:mt-0  text-center flex flex-col items-center space-y-10">
                        <h1 className="text-2xl md:text-5xl lg:text-6xl/tight xl:text-7xl/tight text-gray-200 font-bold max-w-4xl capitalize">{banner.title}</h1>
                        <p className="text-base text-gray-300 text-center max-w-xl">{banner.description}</p>
                    </div>
                </div>
                </div>
            ))
              
            : (
              <PageSection subheading={"Your Gateway to Live Shows! Our easy-to-use ticketing platform makes it simple for individuals to book tickets quickly."} heading={"Your Premier ticketing service"}/>
            )
          )}
          
        </Carousel>
      </div>
      </>
  )

};
export default Banners;
