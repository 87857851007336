import { useState, useEffect, useRef } from "react";
import Spinner from "../components/Spinner";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { SiAdblock } from "react-icons/si";
import { notification, Select } from 'antd';
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
let GhanaCedi = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'GHS',});
const Checkout = () => {
    const { slug, ticketId } = useParams();
    const [eventId, setEventId] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState(0);
    const [processingAmount, setProcessingAmount] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [event, setEvent] = useState(null);
    const [isPlaceOrderDisabled, setIsPlaceOrderDisabled] = useState(false);
    const [isApplyCodeDisabled, setIsApplyCodeDisabled] = useState(false);
    const [hasRestriction, setHasRestriction] = useState(false);
    const [canBookTicket, setCanBookTicket] = useState(false);
    const initialValues = { firstName: "", lastName: "", email: "", phone: "", confirmation: false };
    const [formValues, setFormValues] = useState(initialValues);
    const couponInputElement = useRef(null);
    const [tickets, setTickets] = useState([])
    const [ticketOptions, setTicketOptions] = useState([])
    const navigate = useNavigate();
    const [defaultTicket, setDefaultTicket] = useState("");
    const location = useLocation();
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (location.state) {
            setDefaultTicket(location.state.selectedTicketName)
        }
        fetch(`${BASE_URL}/events/${slug}/tickets/${ticketId}/checkout`)
            .then((res) => {
                if (!res.ok) {
                    res.json().then((info) => {
                        notification.error({
                            message: info.msg,
                            duration: 7,
                        });
                    })
                    setIsPlaceOrderDisabled(true)
                }
                return res.json();
            })
            .then((data) => {
                let eventInfo = data.data
                setEvent(eventInfo);
                setEventId(eventInfo.id)
                updateTickets(eventInfo.tickets, ticketId)
                setIsLoading(false);
                setError(null);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.message);
            });

        // setIsPlaceOrderDisabled(coupon.length > 0)
        // setIsApplyCodeDisabled(coupon.length === 0)
    }, [eventId,ticketId, location.state]);

    useEffect(() => {
        setIsPlaceOrderDisabled(coupon.length > 0)
        setIsApplyCodeDisabled(coupon.length === 0)
    }, [coupon]);

    const handleFormValueChange = (e) => {
        let { name, value } = e.target;
        if (name === "confirmation") {
            value = e.target.checked;
            setChecked(!checked)
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const handleCouponValueChange = (e) => {
        setCoupon(e.target.value)
    }
    const handleCouponApplication = (e) => {
        e.preventDefault();
        if (coupon !== "") {
            const payload = {
                code: coupon,
                total: subtotal,
                eventId: eventId,
                ticketId: ticketId,
            }
                fetch(`${BASE_URL}/coupons/apply`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                })
                .then((res) => {
                    if (!res.ok) {
                        res.json().then((info) => {
                            notification.error({
                                message: info.msg,
                                duration: 7,
                            });
                        })
                    }
                    return res.json();
                })
                .then((res) => {
                    // setSubtotal(res.data.newTotal)
                    setDiscount((res.data.value))
                    calTotal(Number(res.data.value))
                    notification.success({
                        message: res.msg,
                        duration: 7,
                    });
                    return
                })
                .catch((err) => {
                    console.log(err);
                    notification.error({
                        message: "Sorry. Please try again",
                        duration: 7,
                    });
                })
                .finally(() => {
                    setIsPlaceOrderDisabled(false)
                    setIsApplyCodeDisabled(true)
                });
        }
    }

    const validateForm = ({email= true, phone= true, firstName= true, lastName= true, confirmation = true}) => {
        let isValid = true;
        const newErrors = {};

        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && formValues.email === "") {
            newErrors.email = 'Invalid email address';
            isValid = false;
            notification.error({
                message: "Invalid email address",
                placement: 'topRight',
                duration: 7,
            });
        }

        if (confirmation && !formValues.confirmation) {
            newErrors.confirmation = 'Please Confirm Ticket Delivery';
            isValid = false;
            notification.error({
                message: `Please Confirm Ticket Delivery `,
                placement: 'topRight',
                duration: 7,
            });
        }

        if (firstName && formValues.firstName === "") {
            newErrors.firstName = 'Provide your Full Name';
            isValid = false;
            notification.error({
                message: `Provide your Full Name `,
                placement: 'topRight',
                duration: 7,
            });
        }

        // const phoneRegex = /^[0][0-9]{9}$/;
        // !phoneRegex.test(formValues.phone)
        if (phone && formValues.phone === "") {
            newErrors.phone = 'Invalid phone number';
            isValid = false;
            notification.error({
                message: `Invalid phone number`,
                placement: 'topRight',
                duration: 7,
              });
        }

        setFormErrors(newErrors);
        return isValid;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true)
        formValues.quantity = quantity
        formValues.eventId = eventId
        formValues.ticketId = ticketId
        formValues.coupon = coupon
        if (validateForm({email: true, phone: true, firstName: true, lastName: true, confirmation : true})) {
            try {
                const response = await fetch(`${BASE_URL}/events/${eventId}/tickets/${ticketId}/order`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                });

                if (response.ok) {
                    response.json().then((result) => {
                        if (result.data.isFreeOrder) {
                            notification.success({
                                message: result.msg,
                            });
                            setTimeout(() => {
                                navigate(`/order/success/${result.data.order.orderNumber}`)
                            }, 2000);
                        } else if (!result.data.isFreeOrder && result.data.isComplete) {
                            notification.success({
                                message: result.msg,
                                duration: 7,
                            });

                            setTimeout(() => {
                                window.location.href = result.data.paymentLink
                            }, 3000);
                        } else {
                            // redirect to a page where user can try generating a new payment link
                        }

                    })
                } else {
                    let res = await response.json()
                    notification.error({
                        message: res.msg
                    });
                }
            } catch (error) {
                notification.error({
                    message: `Sorry. Please try again`
                });
            }
        } else {
            // Form data is invalid, do something (display errors, etc.)
        }
        setIsSubmit(false)
    };

    const handleInviteCheck = async (e) => {
        e.preventDefault();
        setIsSubmit(true);
        let payload = {
            email: formValues.email,
            phone: formValues.phone
        }
        if (validateForm({firstName : false, lastName: false, confirmation : false})) {
            try {
                const response = await fetch(`${BASE_URL}/events/${eventId}/tickets/${ticketId}/invite`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (response.ok) {
                    let res = await response.json()
                    if (res.data) {
                        setCanBookTicket(true)
                        // setSelectedTicket({...selectedTicket, [quantityPerOrder] : parseInt(res.data.quantity)})
                        notification.success({
                            message: res.msg,
                            duration: 7,
                        });
                    } else {
                        notification.error({
                            message: res.msg,
                            duration: 7,
                        });
                    }
                } else {
                    let res = await response.json()
                    notification.error({
                        message: res.msg,
                        duration: 7,
                    });
                }
                return
            } catch (error) {
                console.log(error);
                console.error('Error submitting form:', error);
                notification.error({
                    message: `Sorry. Please try again`
                });
            }
            finally {
                setIsSubmit(false);
            }
        }
    }

    const incrementQuantity = () => {
        if (quantity < selectedTicket.quantityPerOrder) {
            setQuantity(quantity => quantity + 1);
            let orderTotal = (quantity + 1) * selectedTicket.price
            let amount = getProcessingFeeAmount(orderTotal)
            setSubtotal(orderTotal)
            setTotal(orderTotal + amount);
            setProcessingAmount(Number(amount).toFixed(2))
            setDiscount(0)
            // if (couponInputElement.current) {
            //     couponInputElement.current.disabled = false;
            //     couponInputElement.current.addEventListener('click', handleCouponApplication);
            //     couponInputElement.current.click();
            // }
            if (coupon !== "") {
                setIsPlaceOrderDisabled(coupon.length > 0)
                setIsApplyCodeDisabled(coupon.length === 0)
            }
        }
    };

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity => quantity - 1);
            let orderTotal = (quantity - 1) * selectedTicket.price
            let amount = getProcessingFeeAmount(orderTotal)
            setSubtotal(orderTotal)
            setTotal(orderTotal + amount);
            setProcessingAmount(Number(amount).toFixed(2))
            setDiscount(0)
            // if (couponInputElement.current) {
            //     couponInputElement.current.disabled = false;
            //     couponInputElement.current.click();
            // }
            if (coupon !== "") {
                setIsPlaceOrderDisabled(coupon.length > 0)
                setIsApplyCodeDisabled(coupon.length === 0)
            }
        }
    };

    const getProcessingFeeAmount = (amount) => {
        return (((parseFloat(event.processingFee) / 100) * parseFloat(amount)))
    }

    const calTotal = (discountVal = discount) => {
        let pAmount = getProcessingFeeAmount(parseFloat(subtotal) - parseFloat(discountVal))
        setProcessingAmount(Number(pAmount).toFixed(2))
        setTotal(parseFloat(subtotal) - parseFloat(discountVal) + parseFloat(pAmount))
    }

    const handleTicketChange = (value) => {
        updateTickets(tickets, value) 
    };

    const updateTickets = (tickets, ticketId) => {
        setTickets(tickets)
        const ticket = tickets.filter((ticket) => {
            return parseInt(ticket.id) === parseInt(ticketId)
        })
        const options = [];
        for (let i = 0; i < tickets.length; i++) {
            if (tickets[i].isOnSale && !tickets[i].isSoldOut) {
                let name =  parseFloat(tickets[i].price) > 0 ? GhanaCedi.format(tickets[i].price) : 'Free'
                options.push({
                    value: tickets[i].id,
                    label: tickets[i].name + " (" + name + ") ",
                });
            }
        }
        const ticketPrice = parseFloat(ticket[0].price)
        setTicketOptions(options)
        setSelectedTicket(ticket[0]);
        setSubtotal(ticketPrice)
        let processingFee = (((parseFloat(event.processingFee) / 100) * parseFloat(ticketPrice)))
        setProcessingAmount(Number(processingFee).toFixed(2))
        setTotal(ticketPrice + processingFee)
        setHasRestriction(ticket[0].restriction)
    }


    return (
        <>
        { event ? (
                <DynamicPageHeadContent title={event?.name} description={event?.description} ogDescription={event?.description} />
            ) : (
                <></>
            ) }
            <div className="max-w-full mx-auto mt-16" >
                <div className="flex flex-col lg:flex-row md:flex-row sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32 bg-[#f2f2f7] pb-10 pt-5">
                    <div className="flex-1 mb-10 lg:mb-0 md:mb-0 order-last lg:order-first md:order-first">
                        <div className="items-center bg-[#f2f2f7] py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32 text-center hidden md:block lg:block">
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <h2 className="text-xl font-bold text-gray-800 truncate mt-5">{event?.name}</h2>
                            )}
                        </div>
                        {isLoading ? (
                                <></>
                            ) : (
                                <div className="mt-2 px-8 pt-8 lg:mt-0 rounded-l-lg mb-10 h-full">
                            <div className="flex justify-between flex-col w-full md:flex-row gap-5 items-center">
                                { (defaultTicket && defaultTicket !== "") ? 
                                    (
                                        <>
                                            <div className="w-full lg:w-1/2 md:w-1/2">
                                                <label className="mt-4 mb-2 block text-sm font-semibold">Ticket</label>
                                                <div className="py-2 lg:py-4 flex flex-col items-center w-full justify-between mb-1">
                                                    <div className="w-full">
                                                        <Select
                                                            defaultValue={defaultTicket}
                                                            size={"large"}
                                                            className="w-full"
                                                            onChange={handleTicketChange}
                                                            options={ticketOptions}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                                
                                <div className="flex flex-col rounded-lg w-full lg:w-1/2 md:w-1/2">
                                    {selectedTicket && (
                                        <>
                                            <label className="mt-4 mb-2 block text-sm font-semibold px-2">Quantity</label>
                                            <div className="flex w-full flex-col p-2 lg:p-4">
                                            <div className="flex flex-col items-center w-full justify-between lg:block">
                                                <div className="flex align-center order-1">
                                                    <button className={quantity === 1 || (hasRestriction && !canBookTicket) ? 'cursor-not-allowed opacity-50' : ''} onClick={decrementQuantity}><AiFillMinusCircle size={"30px"} className="fill-primary-500" /></button>
                                                    <h6 className="m-0 px-5 py-2 mx-5 tex-base border border-black rounded-sm">{quantity}</h6>
                                                    <button className={quantity >= selectedTicket.quantityPerOrder || (hasRestriction && !canBookTicket) ? 'cursor-not-allowed opacity-50' : ''} onClick={incrementQuantity}><AiFillPlusCircle size={"30px"} className="fill-primary-500" /></button>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="divide-y"></div>
                            {hasRestriction && !canBookTicket ? (
                                <>
                                    <div className="overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                                        <div className="flex items-end justify-center text-center sm:block sm:p-0">
                                            <span className="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true">​</span>
                                            <div className="inline-block overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-2xl p-5 lg:p-10 my-8 align-middle max-w-xl w-full">
                                                <div className="sm:max-w-lg md:mx-auto flex max-w-lg flex-col items-center  ">
                                                    <SiAdblock size={"40px"} className="fill-red-500" />

                                                </div>
                                                <div className="sm:max-w-lg md:mx-auto">
                                                    <p className="mt-4 text-center font-bold">This is an Invite only Event</p>
                                                    <p className="mx-auto text-center leading-relaxed text-gray-500 mt-5">Please enter your email and phone to confirm your invite</p>

                                                </div>
                                                <div className="sm:max-w-lg md:mx-auto">
                                                    <form action="#" method="post" onSubmit={handleInviteCheck}>
                                                    <div className="flex flex-col md:flex-row lg:flex-row w-full gap-5 mt-5">
                                                        <input type="email" id="email" value={formValues.email} name="email" onChange={handleFormValueChange} className="w-full mb-2 md:mb-0 lg:mb-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" placeholder="Email" required />
                                                        <input type="text" id="phone" value={formValues.phone} name="phone" onChange={handleFormValueChange} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" placeholder="Phone Number" required />
                                                    </div>
                                                    <div className="mt-8 flex flex-col justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 w-full">
                                                        <button onClick={handleInviteCheck} className="whitespace-nowrap rounded-md bg-primary-500 px-4 py-3 font-medium text-white">Check Invite</button>
                                                    </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <hr className="mt-4 mb-0" />
                                    <div className="relative">
                                        <form onSubmit={handleFormSubmit} autoComplete="off">
                                            <div className="flex justify-between flex-col w-full md:flex-row gap-5">
                                                <div className="w-full lg:w-1/2 md:w-1/2">
                                                    <label for="firstName" className="mt-4 mb-2 block text-sm font-medium">Full Name</label>
                                                    <input type="text" id="firstName" value={formValues.firstName} name="firstName" onChange={handleFormValueChange} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" placeholder="Dzifa Jambo" required />
                                                    {formErrors.firstName && <div className="text-red-600 p-2">{formErrors.firstName}</div>}
                                                </div>
                                                <div className="w-full lg:w-1/2 md:w-1/2">
                                                    <label for="email" className="mt-4 mb-2 block text-sm font-medium">Email</label>
                                                    <input type="email" id="email" value={formValues.email} name="email" onChange={handleFormValueChange} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" placeholder="dzifa@gmail.com" required  disabled={hasRestriction && canBookTicket ? true : false} />
                                                    {formErrors.email && <div className="text-red-600 p-2">{formErrors.email}</div>}
                                                </div>
                                            </div>
                                            <div className="flex justify-between flex-col w-full md:flex-row gap-5">
                                                <div className="w-full lg:w-1/2 md:w-1/2">
                                                    <label for="phone" className="mt-4 mb-2 block text-sm font-medium">Phone</label>
                                                    <input type="text" id="phone" value={formValues.phone} name="phone" onChange={handleFormValueChange} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" placeholder="0551111111" required  disabled={hasRestriction && canBookTicket ? true : false}/>
                                                    {formErrors.phone && <div className="text-red-600 p-2">{formErrors.phone}</div>}
                                                </div>
                                            </div>
                                            <div className="flex items-center my-3">
                                                <input type="checkbox" onChange={handleFormValueChange} id="confirmation" name="confirmation" className="accent-primary-500 mt-4 mb-2 mr-2 w-5 h-5 rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" checked={checked} />
                                                <label for="confirmation" className="mt-4 mb-2 block text-sm font-medium">I confirm my E ticket should be delivered to the contact details provided.
                                                {formErrors.confirmation && <><br></br><span className="text-red-600 p-2">{formErrors.confirmation}</span></>}
                                                </label>
                                            </div>
                                                
                                            {/* <div>
                                        <p className="mt-8 text-base font-medium">Payment Method</p>
                                        <div className="mt-5 grid gap-6">
                                            <div className="relative">
                                                <input className="peer hidden" id="radio_2" type="radio" name="radio" checked />
                                                <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
                                                <label className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-2" for="radio_2">
                                                    <div className="ml-5">
                                                        <span className="mt-2 font-semibold text-base">MoMo & Credit Card</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                            {event?.hasCoupon && (

                                                <div className="flex justify-between flex-col w-full gap-5">
                                                    <div className="w-full">
                                                        <label for="coupon" className="mt-4 mb-2 block text-sm font-medium">Coupon Code</label>
                                                        <input type="text" id="coupon" value={coupon} name="coupon" onChange={handleCouponValueChange} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500" placeholder="CODE32" />
                                                    </div>
                                                    <div className="w-full">
                                                        <button type="button" id="couponInputElementButton" ref={couponInputElement} onClick={handleCouponApplication} disabled={isApplyCodeDisabled} className={`${isApplyCodeDisabled ? 'opacity-50' : ''}  !bg-primary-500 mt-4 mb-8 w-full rounded-md  px-6 py-3 font-medium text-white`}>Apply Coupon Code</button>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-10">
                                            <hr className="mt-4 mb-0" />
                                                <div className="flex items-center justify-between my-1">
                                                    <p className="font-base text-gray-900">Subtotal</p>
                                                    <p className="font-semibold text-gray-900">{subtotal > 0 ? GhanaCedi.format(Number(subtotal).toFixed(2)) : 'Free'}</p>
                                                </div>
                                                {event?.hasCoupon && (
                                                    <>
                                                        <div className="flex items-center justify-between my-1">
                                                            <p className="font-base text-gray-900">Discount</p>
                                                            <p className="font-semibold text-gray-900">- { GhanaCedi.format(Number(discount).toFixed(2))}</p>
                                                        </div>
                                                    </>
                                                )}
                                                {Number(event?.processingFee) > 0 && (
                                                    <>
                                                        <div className="flex items-center justify-between my-1">
                                                            <p className="font-base text-gray-900">Processing Fee ({ subtotal > 0 ? `${GhanaCedi.format(Number(event?.processingFee))} %` : 'Free' })</p>
                                                            <p className="font-semibold text-gray-900">+ {processingAmount}</p>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="flex items-center justify-between my-1">
                                                    <p className="font-base text-gray-900">Total Cost</p>
                                                    <p className="font-semibold text-gray-900">{total > 0 ? `${GhanaCedi.format(Number(total).toFixed(2))}` : 'Free'}</p>
                                                </div>
                                            </div>
                                            <button type="button" onClick={handleFormSubmit} disabled={isPlaceOrderDisabled || isSubmit} className={`mt-4 mb-8 w-full mr-2 rounded-md !bg-primary-500 ${isPlaceOrderDisabled || isSubmit ? 'opacity-50' : ''} px-6 py-3 font-medium text-white`}>Buy Ticket
                                                {isSubmit && <Spinner fontSize={10} mini={true} /> } </button>

                                        </form>
                                    </div>
                                </>
                            )}
                        </div>
                            )}
                        
                    </div>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <div className="flex-1 flex items-center justify-center">
                            <div className="bg-[#f2f2f7] bg-cover bg-center h-44 w-72 md:h-[70%] md:w-full lg:h-[70%] lg:w-full rounded-lg" style={{ backgroundImage: `url("${event?.image}")` }}></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export default Checkout;