import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from 'react-scroll';
import { FloatButton } from 'antd';

const SmoothScroll = ({ children }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const [showButton, setShowButton] = useState(false);
  const handleScroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {children}
      <FloatButton.BackTop visibilityHeight={0} onClick={scrollToTop} className={showButton ? `inline-block` : `hidden`}/>
    </div>
  );
};

export default SmoothScroll;
