import PageSection from "../components/Misc/PageSection";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

const OrgTerms = () => {
  return (
    <>
    <DynamicPageHeadContent title={"Platform Terms"} />
      <PageSection heading={"Organizer Terms and Conditions"} subheading={""} sHeight={60} mHeight={60} lHeight={60} />
      <section>
        <div className="flex flex-col items-center justify-center px-5 md:px-10">
          <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:pb-20">
            <h2 className="text-2xl font-bold mt-8 text-center">Jambotickets Event Organizer Terms and Conditions</h2>
            <div className="text-lg  text-gray-800">
              <h2 className="text-2xl font-bold mt-8">Introduction</h2>
              <p className="mt-2">Welcome to Jambotickets. These Terms and Conditions ("Terms") govern your use of our platform as an event organizer. By listing events on our platform, you agree to these Terms.</p>

              <h2 className="text-2xl font-bold mt-8">Account Creation and Maintenance</h2>
              <p className="mt-2">Account Registration: Event organizers must create an account to list events. All information provided must be accurate.
                Account Security: Organizers are responsible for maintaining the confidentiality of their account information.</p>

              <h2 className="text-2xl font-bold mt-8">Event Listings</h2>
              <p className="mt-2">Event Information: Organizers must provide complete and accurate information for each event. <br />
                Updates: Organizers must promptly update event listings to reflect any changes or cancellations.</p>

              <h2 className="text-2xl font-bold mt-8">Ticket Sales and Payment</h2>
              <p className="mt-2">Pricing: Organizers set ticket prices. Jambotickets may charge a service fee as a percentage of ticket sales. <br />
                Payment Processing: Jambotickets handles payment processing. <br /> Payments to Organizers will be disbursed according to the agreed schedule. <br />
                Refunds: Refunds are available for event cancellations and significant changes.</p>

              <h2 className="text-2xl font-bold mt-8">Ticket Delivery</h2>
              <p className="mt-2">Delivery Method: Tickets are delivered electronically.</p>
              <p className="mt-2">Delivery Fees: No additional fees for ticket delivery.</p>

              <h2 className="text-2xl font-bold mt-8">Event Changes</h2>
              <p className="mt-2">Notification: Organizers must notify Jambotickets of any changes to events. <br />
                Acceptability: Jambotickets will assess the acceptability of event changes.</p>

              <h2 className="text-2xl font-bold mt-8">Organizer Conduct</h2>
              <p className="mt-2">
                Transparency: Organizers must accurately represent their events on the platform, including event details, ticket prices, and availability.<br />
                Compliance: Organizers must comply with all applicable laws and regulations regarding event organization and ticket sales.<br />
                Communication: Organizers must promptly respond to inquiries from customers and Jambotickets regarding their events.<br />
                Fair Practices: Organizers must not engage in fraudulent activities, misrepresent events, or engage in any behavior that may harm the reputation of Jambotickets or other organizers on the platform.
              </p>

              <h2 className="text-2xl font-bold mt-8">Privacy and Data Protection</h2>
              <p className="mt-2">
                Data Collection: Jambotickets collects and processes personal data provided by organizers in accordance with applicable privacy laws.<br />
                Data Usage: Personal data collected from organizers is used for the purpose of facilitating event listings, ticket sales, and communication regarding events.<br />
                Security Measures: Jambotickets implements reasonable security measures to protect the personal data of organizers from unauthorized access, disclosure, alteration, or destruction.<br />
                Data Sharing: Jambotickets may share organizer data with trusted third-party service providers for the purpose of providing platform services, such as payment processing and analytics. <br /> Organizer data will not be sold or shared with third parties for marketing purposes without explicit consent.<br />
                Data Retention: Organizer data will be retained only for as long as necessary to fulfill the purposes outlined in this document or as required by law. Organizers may request the deletion of their data from the platform upon termination of their account.
              </p>


              <h2 className="text-2xl font-bold mt-8">Dispute Resolution</h2>
              <p className="mt-2">

                Mediation: Disputes will be resolved through mediation.
              </p>
              <h2 className="text-2xl font-bold mt-8">Limitation of Liability</h2>

              <p className="mt-2">Company Liability: Jambotickets is not liable for any damages arising from the use of the platform.
                Organizer Liability: Organizers agree to indemnify Jambotickets from any claims arising out of their events.</p>

              <h2 className="text-2xl font-bold mt-8">Amendments</h2>
              <p className="mt-2">

                Changes to Terms: Jambotickets reserves the right to modify these Terms at any time. Organizers will be notified of significant changes.
              </p>
              <h2 className="text-2xl font-bold mt-8"> Termination</h2>
              <p className="mt-2">
                Termination by Organizer: Organizers may terminate their account at any time.
                Termination by Jambotickets: Jambotickets reserves the right to suspend or terminate an Organizer's account for violations of these Terms.
              </p>

              <h2 className="text-2xl font-bold mt-8">Contact Information</h2>
              <p className="mt-2">

                Support: For questions about these Terms, please contact us at info@jambotickets.com / +233 50 137 3919.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default OrgTerms;
