import { useNavigate } from 'react-router-dom';
import { TwitterOutlined, FacebookOutlined, InstagramOutlined, CopyrightOutlined } from '@ant-design/icons';

const FooterItem = ({ text, link }) => {
    const navigate = useNavigate();
    return (
        <li>
            {  link.startsWith("https") 
            ?
            <a href={link} target='_blank' rel="noreferrer" className="duration-200">{text}</a>
            : 
                <a onClick={() => navigate(`${link}`) } href="#" className="duration-200">{text}</a>
            }
        </li>
    )
    }
     
    const FooterBlockItem = ({ title, items }) => {
    return (
        <div className="space-y-6">
            <h1 className="text-lg font-semibold text-gray-100">
                {title}
            </h1>
            <ul className="space-y-3">
                {
                    items.map((item, i) => (
                        <FooterItem key={i} {...item}/>
                    ))
                }
            </ul>
        </div>
    )
    }
     
    const footerBlocks = [
    {
        id: 1,
        title: "Services",
        items: [
            // {
            //     id: 1,
            //     text: "Ticketing",
            //     link: "#"
            // },
            // {
            //     id: 2,
            //     text: "Event Essentials",
            //     link: "#"
            // },
            // {
            //     id: 3,
            //     text: "Social Media Engagement",
            //     link: "#"
            // },
            {
                id: 4,
                text: "Attendee Engagement",
                link: "#"
            },
            {
                id: 5,
                text: "Attendee Management",
                link: "#"
            },
        ]
    },
    {
        id: 2,
        title: "Company",
        items: [
            {
                id: 3,
                text: "Contact Us",
                link: "/contact"
            },
            {
                id: 4,
                text: "Organizers",
                link: "/organizer"
            },
            {
                id: 5,
                text: "Gallery",
                link: "/gallery"
            },
        ]
    },
    // {
    //     id: 3,
    //     title: "Social",
    //     items: [
    //         {
    //             id: 1,
    //             text: "Twitter X",
    //             link: "https://www.twitter.com/jambotickets"
    //         },
    //         {
    //             id: 2,
    //             text: "Instagram",
    //             link: "https://www.instagram.com/jambotickets"
    //         },
    //         {
    //             id: 3,
    //             text: "Threads",
    //             link: "#"
    //         },
    //         {
    //             id: 4,
    //             text: "Facebook",
    //             link: "https://www.facebook.com/jambotickets"
    //         },
    //         {
    //             id: 5,
    //             text: "Youtube",
    //             link: "#"
    //         },
    //     ]
    // },
    {
        id: 4,
        title: "Resources",
        items: [
            {
                id: 1,
                text: "Blog",
                link: "/blogs"
            },
            {
                id: 2,
                text: "Attendee Privacy",
                link: "/privacy"
            },
            {
                id: 3,
                text: "Refund Policy",
                link: "/terms"
            },
            {
                id: 4,
                text: "FAQ",
                link: "/#faqs"
            },
            {
                id: 4,
                text: "Terms & Conditions",
                link: "/organizer/terms"
            },
        ]
    },
    ]
     
const logoUrl = process.env.PUBLIC_URL + '/img/logo.png';
const bgImage = process.env.PUBLIC_URL + '/img/footer-blob.svg';
     
    const FooterBlock = () => {
        const navigate = useNavigate();
    return (
        <footer className="bg-[#232323] text-gray-300 py-10">
            <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5  py-4 lg:py-32 flex flex-col lg:flex-row gap-14 bg-right-top bg-no-repeat" style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="py-6 px-5 lg:w-96">
                    <div className="inline-flex items-center">
                        <img className="w-14" src={logoUrl} alt="logo" />
                    <a onClick={() => navigate("/")} href="#" className='text-2xl md:text-3xl lg:text-3xl'>
                        Jambotickets
                    </a>
                    </div>
                    
                    <p className="max-w-lg mb-5">Your Seamless Solution For Effortless Event Management.</p>
                    <div className="flex w-full gap-5">
                        <a href="https://www.facebook.com/jambotickets" aria-label="social link">
                        <FacebookOutlined style={{ fontSize: '25px' }}/>
                        </a>
                        <a href="https://www.instagram.com/jambotickets" aria-label="social link">
                        <InstagramOutlined style={{ fontSize: '25px' }}/>
                        </a>
                        <a href="https://www.twitter.com/jambotickets" aria-label="social link">
                        <TwitterOutlined style={{ fontSize: '25px' }}/>
                        </a>
                    </div>
                </div>
                <nav className="lg:flex-1 grid grid-cols-2 md:grid-cols-4 gap-10 justify-between py-6 px-5">
                    {
                        footerBlocks.map((footerBlock, i) => (
                            <FooterBlockItem key={i} {...footerBlock} />
                        ))
                    }
                </nav>
            </div>
            <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5 space-y-5 pb-10 ">
                <div className="px-5 sm:px-10 md:px-12 lg:px-5 flex justify-center text-center py-3 bg-[#41315f] rounded-md border-slate-400">
                    <p> <CopyrightOutlined /> { new Date().getFullYear() } Jambotickets. All right reserved </p>
                </div>
            </div>
        </footer>
    )
    }
     
    export default FooterBlock