import React from 'react'
import Partners from "../components/Partners";
import FAQs from "../components/FAQs";
import { IoTicketOutline } from "react-icons/io5";
import { LuUserCog } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";
import { SiGooglemarketingplatform } from "react-icons/si";
import { IoIosPhonePortrait } from "react-icons/io";
import { FcSurvey } from "react-icons/fc";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoAccessibilityOutline } from "react-icons/io5";
import ScrollReveal from '../ScrollReveal';
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

export default function Organizer() {
  const services = [
    { icon: <IoTicketOutline />, title: "Ticketing" },
    { icon: <LuUserCog />, title: "Attendee Management & Engagement" },
    { icon: <SiGooglemarketingplatform />, title: "Social Media Marketing", },
    { icon: <MdOutlinePayments />, title: "Cashless Payments" },
    { icon: <IoIosPhonePortrait />, title: "Attendee CheckIn Apps" },
    { icon: <FcSurvey />, title: "Live Polling & Surveys" },
    { icon: <LuLayoutDashboard />, title: "Organizer Dashboard" },
    { icon: <IoAccessibilityOutline />, title: "Event Essentials" }
  ];

  return (
    <>
  <DynamicPageHeadContent title={"Event Organizers"} />
      <section className={`bg-gray-900 py-32 sm:py-36 lg:py-40 overflow-hidden h-[60dvh] md:h-[60dvh] lg:h-[60dvh] min-h-max flex items-center relative`}>
                <div className="absolute top-0 left-0 -translate-x-[54%] -translate-y-[70%] w-2/5 rounded-full aspect-square bg-primary-600/70
  backdrop-filter blur-3xl opacity-50" />
                <div className="absolute bottom-0 right-0 translate-x-[54%] translate-y-[70%] w-2/5 rounded-full aspect-square bg-primary-600/70
  backdrop-filter blur-3xl opacity-50" />
                <div className="absolute min-w-[300px] w-[48%] md:w-2/5 aspect-square rounded-full bg-gradient-to-r from-primary-400/5 right-0
  -translate-y-[40%] translate-x-[40%] top-0">
                    <div className="inset-[10%] rounded-full bg-gradient-to-l from-primary-400/20">
                        <div className="absolute inset-[20%] rounded-full bg-gradient-to-l from-primary-400/30" />
                    </div>
                </div>
                <div className="absolute min-w-[300px] w-[48%] md:w-2/5 aspect-square rounded-full bg-gradient-to-l from-primary-400/5 left-0
  translate-y-[40%] -translate-x-[40%] bottom-0">
                    <div className="inset-[10%] rounded-full bg-gradient-to-r from-primary-400/40">
                        <div className="absolute inset-[20%] rounded-full bg-gradient-to-r from-primary-400/50" />
                    </div>
                </div>
                <div className="mx-auto lg:max-w-7xl w-full px-5 sm:px-10 md:px-12 lg:px-5">
                    <div className="text-center flex flex-col items-center space-y-10">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl/tight xl:text-6xl/tight text-white font-bold max-w-4xl capitalize">Your Seamless Solution for Effortless Event Management.</h1>
                        <div className='flex justify-center gap-4'>
                          <a href={process.env.REACT_APP_BACKEND_BASE_URL + "/organizer/login"} className="w-36 rounded-md px-6 py-3 text-center font-semibold bg-primary-500 text-white">Sign In</a>
                          <a href={process.env.REACT_APP_BACKEND_BASE_URL + "/organizer/signup"} className="w-36 rounded-md px-6 py-3 text-center font-semibold border border-primary-500 hover:bg-primary-400 text-white">Sign Up</a>
                        </div>
                    </div>
                </div>
          </section>
      <section>
        <div className="h-full px-8 py-24 mx-auto md:px-12 lg:px-32 max-w-7xl relative">
          <div className="text-center">
            <p className="max-w-lg mx-auto my-4 text-base text-zinc-500">Discover</p>
            <h2 className="text-4xl font-semibold tracking-tighter text-zinc-900">Unleash the Power of Event
              Management</h2>
            <p className="max-w-lg mx-auto my-4 text-base text-zinc-500">
              Our event management and ticketing provides you with a range of powerful features to customize your events, analyze data and integrate with social media
            </p>
          </div>
          <div className="grid grid-cols-1 mt-12 text-center gap-x-6 gap-y-12 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16 relative">
            <article>
              <img src="/img/jb-1.jpeg" alt="" className='rounded'/>
              <h4 className="text-2xl font-semibold tracking-tighter text-zinc-900">Customize your events</h4>
              <p className="max-w-lg mx-auto my-4 text-sm text-zinc-500">Easily tailor your events to match your brand and audience with our intuitive and customization options.</p>
            </article>
            <article>
              <img src="/img/jb-1.jpeg" alt="" className='rounded'/>
              <h4 className="text-2xl font-semibold tracking-tighter text-zinc-900">Analyze Event Data</h4>
              <p className="max-w-lg mx-auto my-4 text-sm text-zinc-500">Easily tailor your events to match your brand and audience with our intuitive and customization options.</p>
            </article>
            <article>
              <img src="/img/untitled-44.jpg" alt="" className='rounded bg-black opacity-75' />
              <h4 className="text-2xl font-semibold tracking-tighter text-zinc-900">Integrate with Social Media</h4>
              <p className="max-w-lg mx-auto my-4 text-sm text-zinc-500">Easily tailor your events to match your brand and audience with our intuitive and customization options.</p>
            </article>
          </div>
        </div>
      </section>
      <ScrollReveal>
        <section>
          <div className="h-full px-8 py-24 mx-auto md:px-12 lg:px-32 max-w-7xl relative">
            <div className="text-center">
              <h2 className="text-4xl font-semibold tracking-tighter text-zinc-900">
                Explore Our Capabilities
              </h2>
              <p className="max-w-lg mx-auto mt-4 text-base text-zinc-500">
                All these features are right at your fingertips.
              </p>
            </div>
            <div className="grid grid-cols-2 mt-12 text-center gap-x-6 gap-y-12 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16 relative">
              {services.map((service, i) => (
                <div key={i}>
                  <div>
                    <span className="flex items-center justify-center w-14 h-14 mx-auto rounded-full bg-zinc-100"> {service.icon}
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="font-medium text-zinc-900">{service.title}</h3>
                    <p className="mt-2 text-sm text-zinc-500">
                      {service.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </ScrollReveal>
      <ScrollReveal>
        <Partners />
      </ScrollReveal>
      {/* About  */}
      <ScrollReveal>
        <div className='bg-[#f2f2f7]'>

          <div className="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-16 lg:py-20 ">

            <div className="flex flex-wrap items-center mt-20 text-left">
              <div className="w-full md:w-3/5 lg:w-1/2 px-4">
                <img src="/img/jb.jpg" alt="gem" className="inline-block rounded shadow-lg border border-merino-400" />
              </div>
              <div className="w-full md:w-2/5 lg:w-1/2 px-4 text-center md:text-left lg:pl-12">
                <h3 className="font-bold mt-8 text-xl md:mt-0 sm:text-2xl">
                  About Us
                </h3>
                <p className="sm:text-lg mt-6">
                  Our event management and ticketing platform is designed to streamline the process of organizing and attending events. We provide a comprehensive suite of tools and services to event organizers, enabling them to plan, promote, and execute successful events of all sizes. From conferences and concerts to festivals and fundraisers, our platform empowers organizers to create unforgettable experiences for attendees.
                </p>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-20 text-left">
              <div className="w-full md:w-3/5 lg:w-1/2 px-4">
                <img src="/img/jb-1.jpeg" alt="project members" className="inline-block rounded shadow-lg border border-merino-400" />
              </div>
              <div className="w-full md:w-2/5 lg:w-1/2 px-4 md:order-first text-center md:text-left lg:pr-12">
                <h3 className="font-bold mt-8 text-xl md:mt-0 sm:text-2xl">
                  Mission
                </h3>
                <p className="sm:text-lg mt-6">Our mission is to revolutionize the event industry by providing innovative solutions that simplify event management, enhance attendee experiences, and drive success for organizers. We are committed to leveraging technology to make event planning more efficient, accessible, and enjoyable for everyone involved.
                </p>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-20 text-left">
              <div className="w-full md:w-3/5 lg:w-1/2 px-4">
                <img src="/img/jb-2.png" alt="editor" className="inline-block rounded shadow-lg border border-merino-400" />
              </div>
              <div className="w-full md:w-2/5 lg:w-1/2 px-4 text-center md:text-left lg:pl-12">
                <h3 className="font-bold mt-8 text-xl md:mt-0 sm:text-2xl">
                  Vision
                </h3>
                <p className="sm:text-lg mt-6">Our vision is to become the go-to platform for event organizers and attendees worldwide. We aim to set new standards for excellence in event management by continuously innovating, adapting to changing needs, and delivering unparalleled value to our users. Through our platform, we envision a future where every event is a seamless, engaging, and memorable experience.
                </p>
              </div>
            </div>

            <div className="h-full px-8 py-24 mx-auto md:px-12 lg:px-32 max-w-7xl relative">

            <div className="text-center">
              
              <a href="/contact" className="w-36 rounded-md px-6 py-3 text-center font-semibold bg-primary-500 text-white">Contact Us</a>
            </div>
            </div>
          </div>
        </div>
      </ScrollReveal>




      <ScrollReveal>
        <FAQs getFaqsFor={"organizer"} />
      </ScrollReveal>
    </>
  )
}
