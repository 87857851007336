import React, { useState, useEffect } from 'react'
import Spinner from './Spinner';
import ScrollReveal from "../ScrollReveal";


let BASE_URL = process.env.REACT_APP_BACKEND_URL;
function FAQs({ getFaqsFor }) {
    const [faqs, setFaqs] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true)
            try {
                const response = await fetch(`${BASE_URL}/faqs?type=${getFaqsFor}`);
                const data = await response.json();
                if (data.data && data.data.length > 0) {
                    setFaqs(data.data)
                }
            } catch (err) {
                setError(err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchEvents();
    }, [getFaqsFor]);

    return (
        <>
            <section className="">
                <ScrollReveal>
                    <div className="bg-[#3f355d] py-20">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="text-3xl font-bold leading-tight lg:text-4xl text-white">
                                Frequently asked questions
                            </h2>
                        </div>
                        {isLoading ? (
                            <Spinner />
                        ) : (

                            <div className="mx-auto mt-8 grid max-w-xl divide-y divide-neutral-200 px-4">
                                {faqs.map((faq, i) => (
                                    <>
                                        <div class="py-5" key={i} >
                                            <details class="group">
                                                <summary class="flex cursor-pointer list-none items-center justify-between font-medium text-white">
                                                    <span> {faq.question}</span>
                                                    <span class="transition group-open:rotate-180">
                                                        <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                                            <path d="M6 9l6 6 6-6"></path>
                                                        </svg>
                                                    </span>
                                                </summary>
                                                <p class="group-open:animate-fadeIn mt-3 text-neutral-200">{faq.answer}</p>
                                            </details>
                                        </div>
                                    </>
                                ))}

                            </div>

                        )}
                        {error ?? (
                            <>
                            </>
                        )}
                        <p className="text-center text-white text-base mt-9 px-4">
                            Still have questions? Contact our support on +233 50 137 3919
                        </p>
                    </div>
                </ScrollReveal>
            </section>
        </>
    )
}

export default FAQs