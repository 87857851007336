import { useState, useEffect } from "react";
import { notification } from "antd";

let BASE_URL = process.env.REACT_APP_BACKEND_URL;

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  url = `${BASE_URL}${url}`;

  useEffect(() => {
    fetch(url)
      .then((res) => {
        if (!res.ok) {
          res.json().then((info) => {
            notification.error({
              message: info.msg,
            });
          });
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setData(data.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        notification.error({
          message: err.message,
        });
      });
  }, [url]);

  return { data, isLoading, error };
};

export default useFetch;
