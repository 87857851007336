import { Helmet } from 'react-helmet';

export default function DynamicPageHeadContent({ 
  title = "Your Seamless Solution For Effortless Ticketing and Event Management",
  description = "From innovative event planning features to seamless attendee management, we offer a comprehensive suite of digital solutions tailored to streamline your ticketing and event management process.",
  ogDescription = "Our event management and ticketing platform provides you with a range of powerful features to customize your events, analyze data and integrate with social media."
}) {

  return (
    <>
        <Helmet>
            <title>Jambotickets | {title}</title>
            <meta property="og:description" content={ogDescription} />
            <meta name="description" content={description} />

        </Helmet>
    </>
  )
}
