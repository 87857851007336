import { useState, useEffect } from "react";
import Spinner from "../components/Spinner";
import BlogCard from "../components/BlogCard";
import { Empty } from 'antd';
import PageSection from "../components/Misc/PageSection";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const Blogs = () => {

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            setIsLoading(true)

            try {
                let request = await fetch(`${BASE_URL}/blogs?limit=20`);
                if (request.ok) {
                    const response = await request.json();
                    setBlogs(response.data)
                }
            } catch (err) {
                setError(err)
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchBlogs();
    }, [])


    return (
        <>
        <DynamicPageHeadContent title={"Inside Ghana's Entertainment Scene"} />
        <PageSection heading={"Inside Ghana's Entertainment Scene"} subheading={"Catch all the buzz going down in the industry in Ghana. We serve you with trending entertainment topics and news."} />
            <div className="relative px-3 pt-10 pb-20 mx-auto w-full max-w-7xl">
                {isLoading ? (
                    <Spinner />
                ) : (blogs.length > 0 ? (
                    <section id="allBlogs"
                        className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">

                        {blogs.map((post, index) => (
                            <BlogCard key={index} {...post} />
                        ))}

                    </section>

                ) : (
                    <section className="max-w-lg px-2 py-20 lg:px-4 lg:py-32 mx-auto space-y-1 text-center">
                        <Empty description={"No Blogs Available Now"} />
                    </section>
                )
                )}
                { error === true ?? (
                  <>
                    <div className="mx-auto max-w-screen-lg space-y-12 rounded-b-lg bg-white px-8 pt-10 pb-20 font-serif text-md tracking-wide text-gray-700 sm:shadow-lg">
                        <p>Unable to retrieve blog details at the moment</p>
                    </div>
                </>
                )}
            </div>


        </>
    );
};

export default Blogs;