import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { LuHelpingHand } from "react-icons/lu";

const Navbar = () => {
    const logoUrl = process.env.PUBLIC_URL + '/img/logo.png';
    const [navIsOpened, setnavIsOpened] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const location = useLocation();
    const isMatchedPath = location.pathname === '/organizer';
    const navigate = useNavigate();

    const toggleNavbar = () => {
        setnavIsOpened(navIsOpened => !navIsOpened)
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        navigate(`/search?q=${searchTerm}`)
    }

    useEffect(() => {
        setnavIsOpened(false);
    }, [location])

    function Redirect(url) {
        window.location.replace(url);
    }

    const links = [
        { name: "Discover", url: "/" },
        { name: "Events", url: "/events" },
        { name: "Organizer", url: "/organizer" },
        { name: "Blogs", url: "/blogs" },
        { name: "Contact Us", url: "/contact" },
    ]

    const secondaryLinks = []
    if (isMatchedPath) {
        secondaryLinks.push({ name: "Sign In", url: process.env.REACT_APP_BACKEND_BASE_URL + "/organizer/login" }, { name: "Sign Up", url: process.env.REACT_APP_BACKEND_BASE_URL + "/organizer/signup" }
        )
    } else {
        secondaryLinks.push(
            { name: "Ticket Help Desk", url: "/help-desk" }
        )
    }
    return (
        <header className={`fixed top-0 inset-x-0 z-[999] h-16 flex items-center bg-gradient-to-br from-primary-500 to-gray-700`}>
            <div className="mx-auto lg:max-w-7xl w-full px-5 sm:px-10 md:px-12 lg:px-5 h-full items-center">
                <nav className="flex justify-between items-center h-full">
                    <div className="inline-flex items-center">
                        <img className="w-12" src={logoUrl} alt="Jambotickets logo" />
                        <a onClick={() => navigate("/")} href="#" className='text-xl  text-gray-300'>
                            Jambotickets
                        </a>
                    </div>
            <div className={`
        absolute top-full left-0 bg-gradient-to-br from-primary-500 to-gray-700 lg:bg-transparent border-b border-gray-800 py-8 lg:py-0 px-5 sm:px-10 md:px-12 lg:px-0 lg:border-none w-full lg:top-0 lg:relative lg:w-max lg:flex lg:transition-none duration-300 ease-linear gap-x-6
        ${navIsOpened ? "visible opacity-100 translate-y-0" : "translate-y-10 opacity-0 invisible lg:visible lg:translate-y-0 lg:opacity-100"}
        `}>
                        <ul className="flex flex-col gap-y-5 text-gray-300 lg:items-center lg:flex-row lg:gap-x-5 lg:h-full lg:justify-center lg:flex-1">
                            {
                                links.map((link, idx) => (
                                    <li key={idx}>
                                        <a onClick={() => navigate(`${link.url}`)} href="#" className={`flex lg:justify-center transition ease-linear hover:text-white ${location.pathname === link.url ? "text-gray-100 gap-x-3 underline decoration-blue-300 underline-offset-4" : "text-gray-400 hover:text-white"}`}>
                                            {link.name}
                                        </a>
                                    </li>
                                ))
                            }
                            <div className="max-w-sm lg:max-w-xs w-auto mb-5 lg:mb-0">
                                <form onSubmit={handleSearchSubmit} action="#" className="flex w-full text-gray-700">
                                    <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} type="text" placeholder="Search for events..." className="px-2 py-1 border border-gray-800  rounded-md flex w-full outline-none bg-gray-100" />
                                    <button onClick={handleSearchSubmit} aria-label="search btn" className="outline-none bg-gradient-to-br from-primary-500 to-gray-700 px-3 py-2.5 rounded-r-md min-w-max">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                        </svg>
                                    </button>
                                </form>
                            </div>
                        </ul>
                        <div className="w-full flex sm:w-max lg:min-w-max lg:items-center gap-5">
                            {
                                secondaryLinks.map(link => (
                                    <>

                                        <a onClick={() => link.url === "/help-desk" ? navigate(`${link.url}`) : Redirect(link.url)} href="#" className={`flex justify-center gap-x-3 items-center  ${location.pathname === link.url ? "text-gray-100 gap-x-3  underline decoration-blue-300 underline-offset-4" : "text-gray-400 hover:text-white"}`}>
                                            {link.name}
                                            {link.url === "/help-desk" && <LuHelpingHand />}
                                        </a>


                                    </>
                                ))
                            }

                        </div>
                    </div>
                    <div className="flex items-center justify-end relative z-60 lg:hidden">
                        <button onClick={() => {
                            toggleNavbar()
                        }} className="p-3 rounded-full bg-primary-500 outline-none w-12 aspect-square flex flex-col relative justify-center items-center">
                            <span className="sr-only">
                                toggle navbar
                            </span>
                            <span className={`
              w-6 h-0.5 rounded-full bg-gray-300 transition-transform duration-300 ease-linear
              ${navIsOpened ? "translate-y-1.5 rotate-[40deg]" : ""}
            `} />
                            <span className={`
              w-6 origin-center  mt-1 h-0.5 rounded-full bg-gray-300 transition-all duration-300 ease-linear
              ${navIsOpened ? "scale-x-0 opacity-0" : ""}
            `} />
                            <span className={`
              w-6 mt-1 h-0.5 rounded-full bg-gray-300 transition-all duration-300 ease-linear
              ${navIsOpened ? "-translate-y-1.5 -rotate-[40deg]" : ""}
            `} />
                        </button>
                    </div>
                </nav>
            </div>
        </header>
    )
}
export default Navbar