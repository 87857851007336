import ShortenedText from "../components/ShortenedText";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiMapPinLine } from "react-icons/ri";
import { ImTicket } from "react-icons/im";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Spinner from "../components/Spinner";
import useFetch from "../hooks/useFetch";
import { Flex, Tag, Popover } from 'antd';
import { QuestionCircleOutlined, GroupOutlined, UserOutlined } from '@ant-design/icons';
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

let GhanaCedi = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'GHS',});
const Event = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [selectedTicketName, setSelectedTicketName] = useState("");
    const [selectedTicketIndex, setSelectedTicketIndex] = useState(null);
    const ticketsContainerRef = useRef(null);

    const { data: event, isLoading, error } = useFetch(`/events/${slug}`);
    


    useEffect(() => {
        if (!error && event?.tickets.length > 0) {
            for (let i = 0; i < event?.tickets.length; i++) {
                if (event.tickets[i].isOnSale && !event.tickets[i].isSoldOut  ) {
                    setSelectedTicket(event.tickets[i].id);
                    setSelectedTicketName(event.tickets[i].name)
                    setSelectedTicketIndex(i)
                    break
                }
            }
        }
        const handleScroll = () => {
            if (document.getElementById('ticketSection')) {
                const div2Rect = ticketsContainerRef.current.getBoundingClientRect();
                const tickets = document.querySelector('.sticky-bottom');
                if (div2Rect.top <= 282) {
                    tickets.classList.add('relative');
                    tickets.classList.add('rounded');
                    tickets.classList.remove('fixed');
                    tickets.classList.remove('bottom-0');
                    tickets.classList.remove('left-0');
                } else {
                    tickets.classList.remove('relative');
                    tickets.classList.remove('rounded');
                    tickets.classList.add('fixed');
                    tickets.classList.add('bottom-0');
                    tickets.classList.add('left-0');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [event]);

    const handleChange = (id, index, name) => {
        setSelectedTicket(id);
        setSelectedTicketIndex(index);
        setSelectedTicketName(name)
    };

    return (
        <>
            { event ? (
                <DynamicPageHeadContent title={event?.name} description={event?.description} ogDescription={event?.description} />
            ) : (
                <></>
            ) }

            
            <div className="bg-[#f2f2f7] py-8 mt-16">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        event ? (
                            <div className="flex flex-col md:flex-row -mx-4">
                                <div className="md:flex-1 px-4">
                                    <div className="h-50 md:h-[460px] lg:h-[460px] rounded-lg lg:rounded-2xl md:rounded-2xl bg-gray-300 mb-4">
                                        <img className="w-full h-50 md:h-full lg:h-full object-cover shadow-2xl rounded-lg lg:rounded-2xl md:rounded-2xl" src={event.image} alt={event.name} />
                                    </div>
                                    <div className="my-2">
                                        <span className="font-bold">About this event</span>
                                        <p className="text-gray-600 text-sm mt-2">
                                            <ShortenedText text={event.description} maxLines={2} />
                                        </p>
                                        <div className="flex flex-wrap max-w-xl w-full pr-2 py-2 gap-1 md:gap-2 text-sm text-white md:text-md">
                                        <Flex gap="4px 0" wrap>
                                        {event.tags.split(",").map((tag, i) => {
                                                return (
                                                    <Tag key={i} color="purple">{tag}</Tag>
                                                    )
                                                })}
                                            
                                        </Flex>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:flex-1 px-4">
                                    <h3 className="text-xl font-bold  text-black mb-2">{event.name}</h3>
                                    <div className="border border-gray-300 bg-slate-100/80 rounded p-4">
                                        <div className="my-3">
                                            <p className="text-black text-sm mt-2 flex justify-start gap-2"><FaRegCalendarAlt size='20px' className="text-primary-500 opacity-80" /><span className="text-muted">{event.date}</span></p>
                                        </div>
                                        <div className="my-3">
                                            <p className="text-black text-sm mt-2 flex justify-start gap-2"><RiMapPinLine size='20px' className="text-primary-500 opacity-80" />{event.venue} 
                                            {event.type === "offline" && event.address ? 
                                                <a href={`https://maps.google.com/maps?q=${event.venue}`} target="_blank" className="text-red-800 font-semibold hover:underline focus:outline-none" rel="noreferrer">
                                                    Get Directions
                                                </a>
                                                : (
                                                    <></>
                                                )
                                            }
                                            
                                            </p>

                                        </div>
                                        <div className="my-3">
                                            <p className="text-black text-sm mt-2 flex justify-start gap-2"><UserOutlined style={{ fontSize: '20px'}}  className="text-primary-500 opacity-80" />{event.users.username}</p>
                                        </div>
                                        <div className="my-3">
                                            <p className="text-black text-sm mt-2 flex justify-start gap-2"><GroupOutlined style={{ fontSize: '20px'}} className="text-primary-500 opacity-80" />{event.categories.name}</p>
                                        </div>
                                    </div>
                                    <h3 className="text-lg font-bold text-center text-black my-2">Ticket options</h3>
                                    {event.tickets.length !== 0 ? (
                                        <>
                                            {event.ussdCode !== "" ?
                                                <div id="ticketSection" ref={ticketsContainerRef} className="flex items-center cursor-pointer flex-col rounded-md lg:rounded-xl border border-gray-300 bg-slate-100/80 p-2 pr-1">
                                                    <p>USSD & Offline Ticketing</p>
                                                    <p>USSD Dial Code <a className="font-bold " href={`tel:${event.ussdCode}`}>{event.ussdCode}</a></p>
                                                </div>
                                                : (
                                                    <div id="ticketSection" ref={ticketsContainerRef} ></div>
                                                )
                                            }
                                            <div className="mx-auto my-4 w-full border border-[#E4E4E7] lg:my-5"></div>

                                            <div className="sticky-bottom flex fixed bottom-0 left-0 z-[100] lg:z-0 bg-gradient-to-b from-primary-200 via-primary-500 to-primary-800 lg:bg-white lg:from-white lg:via-white lg:to-white p-3 w-full flex-col lg:pb-8 lg:pt-4 md:relative lg:relative ">
                                                {event.tickets.map((ticket, index) => {
                                                    return (
                                                        <div className={`relative mb-4`} key={index}>
                                                            <input onChange={() => { handleChange(ticket.id, index, ticket.name) }} className="peer hidden" id={`radio_${index}`} type="radio" name="radio" checked={index === selectedTicketIndex && !ticket.isSoldOut && ticket.isOnSale} disabled={ticket.isSoldOut} />
                                                            <span onChange={() => { handleChange(ticket.id, index, ticket.name) }} className="absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-500 bg-white peer-checked:border-gray-900"></span>
                                                            <label onChange={() => { handleChange(ticket.id, index, ticket.name) }} className={`flex cursor-pointer flex-col rounded-md lg:rounded-lg border bg-slate-100/90 p-2 lg:p-4 ${index === selectedTicketIndex && !ticket.isSoldOut ? 'border-primary-500' : 'border-gray-300'}`} for={`radio_${index}`}>
                                                                <div className="flex flex-col lg:flex-row justify-between w-full lg:w-2/3">
                                                                    <span className={`mb-2 text-sm lg:text-base font-semibold ${(ticket.isSoldOut || !ticket.isOnSale) && 'text-gray-500'} `}>{ticket.name} <span className={`pl-3 mb-2 text-sm lg:text-base font-semibold inline md:hidden lg:hidden`}><Popover content={ticket.description} title="Ticket Description" trigger={["hover", "click"]}><QuestionCircleOutlined /></Popover></span></span>
                                                                    <span className={`mb-2 text-sm lg:text-base font-semibold ${(ticket.isSoldOut || !ticket.isOnSale) && 'text-gray-500'} `}>
                                                                        {/* {!ticket.isSoldOut ? parseFloat(ticket.price) > 0 ? `${GhanaCedi.format(ticket.price)}` : "Free" : (!ticket.isOnSale) ? "Not on Sale" : "Sold Out"} */}
                                                                        {(!ticket.isOnSale) ? "Not on Sale" : (!ticket.isSoldOut) ? (parseFloat(ticket.price) > 0 ? `${GhanaCedi.format(ticket.price)}` : "Free") : "Sold Out" }
                                                                    </span>
                                                                    <span className={`mb-2 text-sm lg:text-base font-semibold hidden md:block lg:block`}><Popover content={ticket.description} title="Ticket Description" trigger={["hover", "click"]}><QuestionCircleOutlined /></Popover></span>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                <ImTicket className="text-primary-500 pointer-events-none opacity-10 absolute left-36 top-0 lg:bottom-10 lg:top-0 lg:left-64 h-64 w-64 transform -translate-x-2/3 z-10" />
                                                <button onClick={() => navigate(`/events/${slug}/tickets/${selectedTicket}/checkout?`, { state : {selectedTicketName : selectedTicketName} })} className={`inline-flex items-center justify-center lg:my-2 rounded-md bg-primary-500 py-3 font-medium text-white ${selectedTicket === null ? 'opacity-70 cursor-not-allowed' : ''}`} disabled={selectedTicket === null}>
                                                    Book Now
                                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                                    </svg></button>
                                            </div>
                                        </>
                                    ) : (
                                        <section className="max-w-lg px-2 py-5 lg:px-4 lg:py-20 mx-auto space-y-1 text-center">
                                            <h2 className="text-base lg:text-lg font-medium text-gray-800">No Tickets Available</h2>
                                        </section>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <section className="max-w-lg px-2 py-5 lg:px-4 lg:py-20 mx-auto space-y-1 text-center">
                                <h2 className="text-base lg:text-lg font-medium text-gray-800">Event Not Found</h2>
                                <button onClick={() => navigate(`/events`)} className="mt-5 inline-block items-center rounded-md bg-primary-500 px-6 py-2 text-center font-semibold text-white lg:mr-8">Discover Events</button>
                            </section>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default Event;