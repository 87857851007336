import React, { useState } from 'react';
import { notification, Spin } from 'antd';
import PageSection from "../components/Misc/PageSection";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";
let BASE_URL = process.env.REACT_APP_BACKEND_URL;

const Contact = () => {
    const initialValues = {
        name: '',
        phoneNumber: '',
        email: '',
        eventBrief: ''
    };
    const [formData, setFormData] = useState(initialValues);
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true)
        fetch(`${BASE_URL}/quote`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
        .then((res) => {
            if (!res.ok) {
                res.json().then((info) => {
                    notification.error({
                        message: info.msg,
                    });
                })
            }
            return res.json();
        })
        .then((res) => {
            notification.success({
                message: res.msg,
                duration: 10,
            });
            return
        })
        .catch((err) => {
            console.log(err);
            notification.error({
                message: "Sorry. Please try again",
            });
        })
        setIsSubmit(false)
        setFormData(initialValues)

    };
    return (
        <>
        <DynamicPageHeadContent title={"Contact Us"} />
<PageSection heading={"Let's embark on an exciting journey together!"} subheading={""} sHeight={50} mHeight={50} lHeight={50} />

            <section>
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 pt-32">
                    <div className="grid items-center gap-8 sm:gap-20 lg:grid-cols-2">
                        <div className="flex max-w-2xl flex-col items-start">
                            <h2 className="mb-6 text-3xl font-bold md:text-5xl">Let's build something exciting together!</h2>
                            <p className="mb-6 max-w-lg pb-2 text-[#636262]"></p>
                            <div className="grid gap-6 sm:grid-cols-2">
                                <a href="tel:+243" className="flex gap-x-6 items-start">
                                    <span className="p-3 md:p-3.5 rounded-md bg-primary-900 text-gray-200 flex w-max">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>
                                    </span>
                                    <div className="space-y-0.5 flex flex-col flex-1">
                                        <p className="text-foreground">Call us</p>
                                        <p className="font-semibold text-gray-900 text-lg">+233 50 137 3919</p>
                                    </div>
                                </a>
                                <a href="mailto:" className="flex gap-x-6 items-start">
                                    <span className="p-3 md:p-3.5 rounded-md bg-primary-900 text-gray-200 flex w-max">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                        </svg>
                                    </span>
                                    <div className="space-y-0.5 flex flex-col flex-1">
                                        <p className="text-foreground">Send us a mail</p>
                                        <p className="font-semibold text-gray-900 text-lg">jambotickets@gmail.com</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="mx-auto max-w-xl bg-[#f2f2f7] p-8 text-center rounded">
                            <h3 className="text-2xl font-bold md:text-3xl">Send Us a Message</h3>
                            <p className="mx-auto mb-6 mt-4 max-w-md text-sm text-[#647084]">At Jambotickets, we understand the unique needs of performers and organizers in the dynamic world of events, parties, and shows</p>
                            <form className="mx-auto mb-4 max-w-sm text-left" onSubmit={handleSubmit}>
                                <div className="mb-4 flex flex-col gap-y-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium">Your Name</label>
                                    <input type="text" className="h-9 w-full bg-white px-3 py-6 text-sm rounded" name="name" placeholder="John Doe" value={formData.name} onChange={handleChange} required />
                                </div>
                                <div className="mb-4 flex flex-col gap-y-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium">Phone Number</label>
                                    <input type="number" className="h-9 w-full bg-white px-3 py-6 text-sm rounded" name="phoneNumber" placeholder="0535177022" value={formData.phoneNumber} onChange={handleChange} required />
                                </div>
                                <div className="mb-4 flex flex-col gap-y-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium">Email Address</label>
                                    <input type="email" className="h-9 w-full bg-white px-3 py-6 text-sm rounded" name="email" placeholder="user@jambotickets.com" value={formData.email} onChange={handleChange} required />
                                </div>
                                <div className="mb-8 flex flex-col gap-y-2">
                                    <label htmlFor="field-3" className="mb- font-medium">Tell us about your event</label>
                                    <textarea placeholder="" className="h-auto min-h-[186px] w-full overflow-auto bg-white px-3 py-2 text-sm rounded" name="eventBrief" value={formData.eventBrief} onChange={handleChange} required> </textarea>
                                </div>
                                <div className="bg-primary-500 rounded">
                                    <input type="submit" value="Send Message" className="inline-block w-full rounded cursor-pointer bg-primary-500 px-6 py-4 text-center font-semibold text-white " />
                                    {isSubmit && <Spin size="large"></Spin>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
