import { useNavigate } from "react-router-dom";

const BlogCard = ({ id, cover, publishedDate, category, author, title, content }) => {
    const navigate = useNavigate();

    return (
        <>
            <article onClick={() => navigate(`/blogs/${id}`)} className="flex flex-col gap-4 rounded-md border border-solid border-gray-100 pb-8 md:p-0 bg-gray-50 cursor-pointer">
                <img src={cover} alt="" className="h-auto md:h-60 object-cover" />
                <div className="px-4 py-2 md:px-6 md:py-4">
                    <p className="mb-4 text-sm font-semibold"><span class="rounded text-sm font-semibold text-primary-500 bg-gray-200 px-2 py-1.5">{category}</span></p>
                    <p className="mb-4 text-base md:text-lg  font-semibold">{title}</p>
                    <p class="mb-6 text-[#636262] text-ellipsis text-base">{content} ...</p>
                    <div className="flex">
                        <div className="flex flex-col">
                            <h6 className="text-sm text-gray-500">By: <span className="text-black text-base font-semibold">{author}</span></h6>
                            <div className="flex flex-col lg:flex-row">
                                <p className="text-sm text-gray-500">{publishedDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </>

    )
}
export default BlogCard
