import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageLayout from "./pages/Layout";
import Home from "./pages/Home";
import Organizer from "./pages/Organizer";
import Events from "./pages/Events";
import Checkout from "./pages/Checkout";
import Event from "./pages/Event";
import Contact from "./pages/Contact";
import Search from "./pages/Search";
import Terms from "./pages/Terms";
import OrgTerms from "./pages/OrganizerTerms";
import Privacy from "./pages/Privacy";
import Gallery from "./pages/Gallery";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import NotFound from "./pages/NotFound";
import SmoothScroll from "./SmoothScroll";
import HelpDesk1 from "./pages/HelpDesk1";
import OrderSuccess from "./pages/OrderSuccess";

function App() {

  return (
    <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<PageLayout />}>
              <Route index element={<Home />} />
              <Route path="organizer" element={<Organizer />} />
              <Route path="events" element={<Events />} />
              <Route path="events/:slug" element={<Event />} />
              <Route path="events/:slug/tickets/:ticketId/checkout" element={<Checkout />} />
              <Route path="contact" element={<Contact />} />
              <Route path="search" element={<Search />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="blogs/:id" element={<BlogDetail />} />
              <Route path="terms" element={<Terms />} />
              <Route path="organizer/terms" element={<OrgTerms />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="help-desk" element={<HelpDesk1 />} />
              <Route path="order/success/:orderId" element={<OrderSuccess />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
  );
}

export default App;
