import EventCard from "./EventCard";
import { useState, useEffect } from "react";
import Spinner from "./Spinner";
import ScrollReveal from "../ScrollReveal";
import { useNavigate } from "react-router-dom";
import { Empty } from 'antd';

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const Events = () => {
    const navigate = useNavigate();
    // const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true)

            try {
                const request = await fetch(`${BASE_URL}/events`);
                if (request.ok) {
                    const res = await request.json();
                    if (res.data && res.data.length > 0) {
                        setEvents(res.data)
                    }
                }
            } catch (err) {
                // setError(err)
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchEvents();
    }, [])

    return (
        <div className="relative px-3">
            <ScrollReveal>

                <div className="text-left p-8 flex flex-col lg:flex-row justify-around w-full" id="events">
                    <h2 className="text-3xl md:text-4xl lg:text-4xl text-center font-semibold tracking-tighter text-zinc-900">
                        Spotlight Events
                    </h2>
                </div>

                {isLoading ? (
                    <Spinner />
                ) : (
                    events.length > 0 ? (
                        <>
                            <section id="events"
                                className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-8 mt-5 mb-5">
                                {events.map((event, index) => (
                                    <EventCard
                                        key={index}
                                        index={index}
                                        slug={event.slug}
                                        image={event.image}
                                        name={event.name}
                                        date={event.date}
                                        venue={event.venue}
                                        code={event.ussdCode}
                                    />
                                ))}


                            </section>
                            <div className="text-left p-8 flex flex-col lg:flex-row justify-around w-full" id="events">
                                <button href="#" onClick={() => navigate(`/events`)} className="text-center font-light text-base mb-2 text-primary-500 underline">See More Events</button>
                            </div>
                        </>


                    ) : (
                        <section className="max-w-lg px-2 py-5 lg:px-4 lg:py-20 mx-auto space-y-1 text-center">
                            <Empty description={"No Events"} />
                            <div className="text-left p-8 flex flex-col lg:flex-row justify-around w-full" id="events">
                            </div>
                            <button onClick={() => navigate(`/events`)} className="mt-5 inline-block items-center rounded-md bg-primary-500 px-6 py-2 text-center font-semibold text-white">See Past Events Gallery</button>
                        </section>
                    )
                )}

            </ScrollReveal>

        </div>
    );
};

export default Events;
