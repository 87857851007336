import Events from "../components/Events";
import FAQs from "../components/FAQs";
import Blog from "../components/Blogs";
import Partners from "../components/Partners";
import CTA from "../components/CTA";
import Banner from "../components/Banner";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

const Home = () => {

    return (
        <>
        <DynamicPageHeadContent title="Your Premier Ticketing Platform" />
            <Banner />
            <Events />
            <Partners />
            <FAQs getFaqsFor={'attendee'} />
            <CTA />
            <Blog />
        </>
    )
}
export default Home;