import PageSection from "../components/Misc/PageSection";
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

const Terms = () => {
  return (
    <>
    <DynamicPageHeadContent title={"Privacy Policy"} />
      <PageSection heading={"Privacy Policy"} subheading={"Last Updated as of October 17, 2023"} sHeight={60} mHeight={60} lHeight={60} />
      <section>
        <div className="flex flex-col items-center justify-center px-5 md:px-10">
          <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:pb-20">
            <div className="text-lg  text-gray-800">
              <h2 className="text-2xl font-bold mt-8">SERVICES</h2>
              <p className="mt-2 leading-loose">JamboTickets offers a comprehensive range of design services, including but not limited to graphic design, web design, branding, illustration, and user interface design. The Company will provide the agreed-upon services with professionalism, creativity, and technical expertise, while adhering to industry standards, design principles, and best practices. The specific details, deliverables, timelines, and pricing for each project will be outlined in a separate agreement or proposal, mutually agreed upon by the Company and the Client.</p>

              <h2 className="text-2xl font-bold mt-8">CLIENT RESPONSIBILITIES</h2>
              <p className="mt-2 leading-loose">The Client agrees to provide accurate and timely information, materials, and feedback necessary for the successful completion of the project. The Client is responsible for obtaining any necessary permissions, licenses, or copyrights for materials provided to the Company for use in the project, including but not limited to logos, images, text, and any other intellectual property. The Client acknowledges that delays or failures in providing required materials or feedback may impact project timelines, deliverables, and the overall success of the project.</p>

              <h2 className="text-2xl font-bold mt-8">INTELLECTUAL PROPERTY</h2>
              <p className="mt-2 leading-loose">Any intellectual property rights, including but not limited to copyrights and trademarks, in the final deliverables created by the Company shall be transferred to the Client upon receipt of full payment unless otherwise agreed upon in writing. The Client warrants that any materials provided to the Company for use in the project do not infringe upon the intellectual property rights of any third party.</p>

              <h2 className="text-2xl font-bold mt-8">PAYMENT</h2>
              <p className="mt-2 leading-loose">The Client agrees to pay the Company the agreed-upon fees for the services rendered. Payment terms, including the amount, method, and schedule, will be specified in the separate agreement or proposal. The Company reserves the right to suspend or terminate services in the event of non-payment or late payment.</p>

              <h2 className="text-2xl font-bold mt-8">CONFIDENTIALITY</h2>
              <p className="mt-2 leading-loose">The Company and the Client agree to keep confidential any proprietary or sensitive information disclosed during the course of the project. Both parties shall take reasonable measures to protect such information from unauthorized access or disclosure.</p>

              <h2 className="text-2xl font-bold mt-8">LIMITATION OF LIABILITY</h2>
              <p className="mt-2 leading-loose">The Company shall not be liable for any direct, indirect, incidental, or consequential damages arising out of the use or inability to use the services provided. The Client acknowledges that the Company's liability is limited to the amount paid for the services rendered.</p>

              <h2 className="text-2xl font-bold mt-8">TERMINATION</h2>
              <p className="mt-2 leading-loose">Either party may terminate this Agreement with written notice if the other party breaches any material provision and fails to remedy the breach within a reasonable time. In the event of termination, the Client shall pay the Company for the services provided up to the termination date.</p>

              <h2 className="text-2xl font-bold mt-8">GOVERNING LAW</h2>
              <p className="mt-2 leading-loose">This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of this Agreement shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].</p>

              <p className="mt-2 leading-loose">By accessing, browsing, or utilizing any design services, communication channels, or materials provided by JamboTickets, including but not limited to graphic design, web design, branding, illustration, and user interface design, whether through our website, email, phone, or any other means, you expressly acknowledge, understand, and agree that you have carefully read, comprehended, and fully consent to be legally bound by all the provisions, terms, and conditions set forth in these Terms of Service, including any additional agreements, policies, guidelines, or amendments referenced or incorporated herein.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Terms;
