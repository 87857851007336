import React from 'react'
import { SlCalender } from "react-icons/sl";
import { CiLocationOn } from "react-icons/ci";
import { BiRename } from "react-icons/bi";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

function EventCard({ index, slug, image, date, name, venue, code}) {
  const navigate = useNavigate();

  return (
    <>
          <div key={index} className="w-72 bg-white shadow-md rounded duration-500 hover:scale-105 hover:shadow-xl relative">
            {code !== "" ? (
              <div className="absolute text-white bg-primary-500 -right-2 top-2 px-5 py-2 rounded"><a href={`tel:${code}`}>{code}</a></div>
            ) : ""}
              <button onClick={() => navigate(`/events/${slug}`)}  href="#">
                  <img src={image}
                      alt={image} className="h-60 w-72 object-cover rounded-t" />
                  <div className="px-4 py-3 w-72">
                      <span className="text-gray-700 mr-3 uppercase text-xs flex justify-start gap-2"><SlCalender className='w-5'/> <span>{date}</span></span>
                      <p className="text-md font-bold text-black truncate capitalize mt-2 flex gap-2 my-2"><BiRename className='w-5'/> <span className=''>{name}</span></p>
                      <p className="flex items-center text-md  text-black cursor-auto my-2 gap-2"><CiLocationOn className='w-5'/> <span className='truncate'>{venue}</span></p>
                      <div className="text-center content-center">
                          <button onClick={() => navigate(`/events/${slug}`)} href="#" className="bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 my-2 focus:outline focus:outline-none transition duration-300 w-full py-2 md:py-3 flex justify-center items-center rounded-md text-center font-semibold">Get Tickets <MdArrowOutward size="15px" /></button>
                      </div>
                  </div>
              </button>
          </div>
    </>
  )
}

export default EventCard