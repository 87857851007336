import DynamicPageHeadContent from "../components/DynamicPageHeadContent";
import { useState } from "react";
import { notification, Badge } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import Spinner from "../components/Spinner";

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const HelpDesk1 = () => {
    const [claim, setClaim] = useState("");
    const [isIsSubmitting, setIsSubmit] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [ticket, setTicket] = useState(null);

    const handleChange = (e) => {
        setClaim(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true)
        fetch(`${BASE_URL}/attendee/unverified/tickets?claim=${claim}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (!res.ok) {
                    res.json().then((info) => {
                        notification.error({
                            message: info.msg,
                        });
                    })
                    return
                }
                return res.json();
            })
            .then((res) => {
                if (res.data) {
                    notification.success({
                        message: res.msg,
                        duration: 10,
                    });
                    setTicket(res.data)
                    return
                }else{
                    notification.error({
                        message: "Ticket Not Found.",
                    });
                    setTicket(null)
                    return
                }
            })
            .catch((err) => {
                console.log(err);
                notification.error({
                    message: "Sorry. Please try again",
                });
            })
        setIsSubmit(false)
    };

    const handleDownload = (e) => {
        if (ticket.id && ticket.orderNumber) {
            setIsDownloading(true)
            fetch(`${BASE_URL}/attendee/unverified/tickets/${ticket.id}/${ticket.orderNumber}/download`, {
              method: 'GET',
            })
            .then((res) => {
                if (!res.ok) {
                    res.json().then((info) => {
                        notification.error({
                            message: info.msg,
                        });
                    })
                    return
                }
                return res.json();
            })
            .then((res) => {
                if (res.data) {
                    notification.success({
                        message: res.msg,
                        duration: 10,
                    });
                    window.location.replace(res.data);
                    return
                }else{
                    notification.error({
                        message: "Ticket Not Found.",
                    });
                    setTicket(null)
                    return
                }
            })
            .catch((err) => {
                console.log(err);
                notification.error({
                    message: "Sorry. Please try again",
                });
            })
            .finally(() => {
                setIsDownloading(false)
            })
        }

    };
    
    return (
        <>
            <DynamicPageHeadContent title={"Ticket Help Desk"} />
            <section className='relative px-3 my-20'>
                <div className="max-w-screen-xl mx-auto py-10 bg-gray-100  mb-20">
                    <h2 className="text-center text-2xl lg:text-4xl md:text-4xl font-semibold tracking-tighter text-zinc-900 py-4">Find your tickets easily!</h2>
                    <div>
                        { !ticket <= 0 ?? (
                            <>
                                <div className="px-5 lg:px-32 md:px-32 py-4">
                                    <div >
                                        <div className='container mx-auto max-w-xl bg-gray-200 p-5 mt-5 rounded'>
                                            <p className="text-gray-700 mt-5 mb-5 text-center">Enter <span className="font-semibold">Order ID</span> / <span className="font-semibold">Payment Reference</span> to access all your event tickets in one place.</p>
                                            <form onSubmit={handleSubmit}>
                                                <div className="flex flex-col items-center justify-center gap-5 px-3">
                                                    <input type="claim" id="claim" name="claim"
                                                        className="w-full max-w-sm rounded border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500"
                                                        placeholder="Payment Reference or Order Id [03H1151140]" value={claim} onChange={handleChange} required />
                                                </div>
                                                <div className="py-10 text-center">
                                                    <button type="submit" className={`px-10 !bg-primary-500 text-white font-semibold py-2 rounded ${isIsSubmitting && 'disabled'}`} >
                                                        Retrieve Ticket   {isIsSubmitting && <Spinner fontSize={15} mini={true} />}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )}
                        <div className="items-center px-2">
                            {ticket ? (
                                <>
                                <div className="flex gap-4 items-center justify-center py-5">
                                    <input type="claim" id="claim" name="claim"
                                                        className="max-w-sm rounded border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500"
                                                        placeholder="Payment Reference or Order Id [03H1151140]" value={claim} onChange={handleChange} required />
                                    <button type="submit" onClick={handleSubmit} className={`px-8 !bg-primary-500 text-white font-semibold py-2 rounded ${isIsSubmitting && 'disabled cursor-not-allowed'}`} >
                                                        Search for Ticket   {isIsSubmitting && <Spinner fontSize={15} mini={true} />}
                                                    </button>
                                </div>
                                <div className="flex items-center justify-center">
                                <Badge.Ribbon className="z-10" text={`${ticket.paymentStatus === true ? 'Paid' : 'Not Paid'}`} color={`${ticket.paymentStatus === true ? 'green' : 'red'}`}>
                                    <div class="bg-white max-w-5xl shadow overflow-hidden rounded-lg md:rounded-none w-full">
                                        <div class="px-4 py-5 sm:px-6">
                                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                                #{ticket.orderNumber}   |   {ticket.event} 
                                            </h3>
                                        </div>
                                        <div class="border-t border-gray-200 w-full">
                                            <dl>
                                                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt class="text-sm font-medium text-gray-500">
                                                        Full name
                                                    </dt>
                                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {ticket.fullname}
                                                    </dd>
                                                </div>
                                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt class="text-sm font-medium text-gray-500">
                                                        Attendee Info
                                                    </dt>
                                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {ticket.email} | {ticket.phone}
                                                    </dd>
                                                </div>
                                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt class="text-sm font-medium text-gray-500">
                                                        Ticket
                                                    </dt>
                                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {ticket.ticket} | {ticket.quantity}x
                                                    </dd>
                                                </div>
                                                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt class="text-sm font-medium text-gray-500">
                                                    Venue
                                                    </dt>
                                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {ticket.venue} | {ticket.date}
                                                    </dd>
                                                </div>
                                            </dl>
                                            <div className="flex items-center justify-center py-10">
                                                { ticket.paymentStatus === true ? (
                                                    <>
                                                        <button onClick={handleDownload} className={`px-8 !bg-primary-500 text-white font-semibold py-2 rounded ${isDownloading && 'disabled cursor-not-allowed'}`} >
                                                            Download Tickets {!isDownloading && <DownloadOutlined /> }  {isDownloading && <Spinner fontSize={15} mini={true} />}
                                                        </button>
                                                    </>
                                                ) : '' }
                                            
                                            </div>
                                            
                                        </div>
                                    </div>
                                </Badge.Ribbon>
                                </div>
                                </>
                            ) : (
                                <div className="px-5 lg:px-32 md:px-32 py-4">
                                    <div >
                                        <div className='container mx-auto max-w-xl bg-gray-200 p-5 mt-5 rounded'>
                                            <p className="text-gray-700 mt-5 mb-5 text-center">Enter <span className="font-semibold">Order ID</span> / <span className="font-semibold">Payment Reference</span> to access all your event tickets in one place.</p>
                                            <form onSubmit={handleSubmit}>
                                                <div className="flex flex-col items-center justify-center gap-5 px-3">
                                                    <input type="claim" id="claim" name="claim"
                                                        className="w-full max-w-sm rounded border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-primary-500 focus:ring-primary-500"
                                                        placeholder="Payment Reference or Order Id [03H1151140]" value={claim} onChange={handleChange} required />
                                                </div>
                                                <div className="py-10 text-center">
                                                    <button type="submit" className={`px-10 !bg-primary-500 text-white font-semibold py-2 rounded ${isIsSubmitting && 'disabled cursor-not-allowed'}`} >
                                                        Retrieve Ticket   {isIsSubmitting && <Spinner fontSize={15} mini={true} />}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default HelpDesk1;
