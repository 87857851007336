import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function Spinner({ fontSize = 36, mini=false}) {
    return (
        <>
        { mini ? (
                <Spin className='mx-4' indicator={<LoadingOutlined style={{ fontSize: fontSize }} spin />} />
        ) : (
            <div className="max-w-lg px-2 py-28 lg:px-4 lg:py-48 mx-auto space-y-1 text-center">

            <Spin className='mx-2' indicator={<LoadingOutlined style={{ fontSize: fontSize }} spin />} />
            </div>
        ) }
            
        </>
    )
}
export default Spinner