import { useState, useEffect } from "react";
import EventCard from "../components/EventCard";
import Spinner from "../components/Spinner";
import { Empty } from 'antd';
import PageSection from "../components/Misc/PageSection";
import { useNavigate } from 'react-router-dom';
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";


let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const Events = () => {
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true)

            try {
                let request = await fetch(`${BASE_URL}/events?limit=20`);
                if (request.ok) {
                    const response = await request.json();
                    if (response.data && response.data.length > 0) {
                        setEvents(response.data)
                    }
                }
            } catch (err) {
                setError(err)
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchEvents();
    }, [])

    return (
        <>
        <DynamicPageHeadContent title="Events" />
<PageSection heading={"Upcoming Events"} subheading={"Uncover Exciting and Thrilling Activities in Your Vicinity"} sHeight={50} mHeight={50} lHeight={50} />
            {isLoading ? (
                <Spinner />
            ) : (events.length > 0 ? (
                    <section id="allEvents"
                    className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-8 mt-20 mb-20">
                    {events.map((event, index) => (
                        <EventCard
                            index={index}
                            slug={event.slug}
                            image={event.image}
                            name={event.name}
                            date={event.date}
                            venue={event.venue}
                            code={event.ussdCode}
                        />
                    ))}

                </section>

            ) : (
                <section className="max-w-lg px-2 py-5 lg:px-4 lg:py-20 mx-auto space-y-1 text-center my-10">
                    <Empty description={"No Events"}/>
                </section>
            )
            )} 
            { error === true ?? (
                  <>
                  <div className="mx-auto max-w-screen-lg space-y-12 rounded-b-lg bg-white px-8 pt-10 pb-20 font-serif text-md tracking-wide text-gray-700 sm:shadow-lg">
                    <p>Unable to retrieve event details at the moment</p>
                </div>
                  </>
                )}
            <section className="max-w-lg px-2 py-5 lg:px-2 lg:py-10 mx-auto space-y-1 text-center mb-20">
                    <p className="text-gray-600">
                        <button onClick={() => navigate("/gallery" )} className="mt-5 inline-block items-center rounded-md bg-primary-500 px-6 py-2 text-center font-semibold text-white lg:mr-8">See Past Events Gallery</button>
                    </p>
                </section>
        </>
    );
};

export default Events;