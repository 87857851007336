import React, { Component } from "react";
import ScrollReveal from "../ScrollReveal";

export default class Partners extends Component {
    render() {
        return (
            <>
            <div className="bg-white">
                <section className="px-4 py-24 mx-auto max-w-7xl " id="brands">
                    <ScrollReveal>

                    <div className="text-center">
                        <p className="max-w-lg mx-auto mt-4 text-left lg:text-center text-base lg:text-2xl ">
                            Ghana's best entertainment brands use our tools to seamlessly manage events, connect with attendees, and streamline logistics.
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-10 text-center lg:grid-cols-4 px-16 py-8">
                        <div className="flex flex-col items-center justify-center gap-4">
                            <p className="text-sm">Partners</p>
                            <h2 className="text-3xl font-bold md:text-6xl">10+</h2>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4">
                            <p className="text-sm">Events</p>
                            <h2 className="text-3xl font-bold md:text-6xl">10+</h2>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4">
                            <p className="text-sm">Attendees</p>
                            <h2 className="text-3xl font-bold md:text-6xl">500+</h2>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-4">
                            <p className="text-sm">Tickets Sold</p>
                            <h2 className="text-3xl font-bold md:text-6xl">1000+</h2>
                        </div>
                    </div>
                    </ScrollReveal>
                </section>
            </div>
            </>
        );
    }
}