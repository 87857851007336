import { useState, useEffect } from 'react'
// import QRCodeComponent from '../components/QRCode'
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from "../components/Spinner";
import { Image } from 'antd';

let BASE_URL = process.env.REACT_APP_BACKEND_URL;
let GhanaCedi = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'GHS',});
function OrderSuccess() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState(null)
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
    fetch(`${BASE_URL}/orders/${orderId}`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Error fetching data");
        }
        return res.json();
      })
      .then((data) => {
        setOrder(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });

  }, [orderId]);

  return (
    <>
      <div className="bg-[#3c3957] lg:bg-white lg:rounded-lg max-w-screen-xl mx-auto py-24">
        <div className="h-auto">
          <div className="bg-[#3c3957] p-6  md:mx-auto">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
                  <path fill="currentColor"
                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                  </path>
                </svg>
                <div className="text-center">
                  <h3 className="md:text-2xl text-base text-white font-semibold text-center">Tickets Purchased & Payment Done!</h3>
                  <p className="text-white my-2">Thank you for completing your secure online payment.</p>
                  <p className="text-white my-2">Ticket Information has been sent to your email</p>
                  <div className="flex flex-col lg:flex-row gap-5 text-center max-w-screen-xl mx-auto justify-center py-10">
                    <div className='content-center items-center'>
                    <Image
                      width={300}
                      src={order?.events.image}
                    />
                      {/* <QRCodeComponent value={"FNKFKSNFKN2308"} /> */}
                    </div>
                    <div className="content-center items-center text-xl text-white text-left">
                      <p className='text-base'>Attendee: <span className='ml-4 text-slate-400 text-base'>{order?.users_orders_attendeeIdTousers.firstName + ' ' + order?.users_orders_attendeeIdTousers.lastName}</span></p>
                      <p className='text-base'>Info: <span className='ml-4 text-slate-400 text-base'>{order?.users_orders_attendeeIdTousers.email + ' / ' + order?.users_orders_attendeeIdTousers.phone}</span></p>
                      <p className='text-base'>Order: <span className='ml-4 text-slate-400 text-base'>#{order?.orderNumber}</span></p>
                      <p className='text-base'>Event: <span className='ml-4 text-slate-400 text-base'>{order?.events.name}</span></p>
                      <p className='text-base'>Tickets: <span className='ml-4 text-slate-400 text-base'>{order?.quantity} x {order?.tickets.name}</span></p>
                      <p className='text-base'>Venue: <span className='ml-4 text-slate-400 text-base'>{order?.events.address}</span></p>
                      <p className='text-base'>Total: <span className='ml-4 text-slate-400 text-base'> { order?.amount > 0 ? GhanaCedi.format(order?.amount) : 'Free'}</span></p>
                    </div>
                  </div>

                  <div className="py-10 text-center">
                    <button onClick={() => navigate("/help-desk")} className="px-12 bg-primary-500 text-white font-semibold py-3 rounded">
                      Go to Ticket Help Desk
                    </button>
                  </div>
                </div>
              </>
            )}

            {error === true ?? (
                  <>
                    <div className="mx-auto max-w-screen-lg space-y-12 rounded-b-lg bg-white px-8 pt-10 pb-20 font-serif text-md tracking-wide text-gray-700 sm:shadow-lg">
                        <p>Unable to retrieve order details at the moment</p>
                    </div>
                </>
                )}

          </div>
        </div>
      </div>
    </>
  )
}

export default OrderSuccess