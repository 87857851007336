import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Spinner from "../components/Spinner";
import { Flex, Tag } from 'antd';
import DynamicPageHeadContent from "../components/DynamicPageHeadContent";

const HtmlToReactParser = require('html-to-react').Parser;

const BlogDetail = () => {
  const { id } = useParams();
  const { data: blog, isLoading, error } = useFetch(`/blogs/${id}`);
  const htmlToReactParser = new HtmlToReactParser();

  return (
    <>
    { !isLoading ?? (
      <DynamicPageHeadContent title={blog.title} />
    )}
      <div className="pt-16 md:pt-24 pb-2 bg-gray-50">
        <main>
          <article>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <header className="mx-auto mt-0 max-w-screen-lg rounded-t-lg bg-white  text-center shadow-lg pt-10">
                  <p className="text-gray-500 text-sm md:text-md">Published {blog.publishedDate}</p>
                  <h1 className="mt-2 text-3xl font-bold text-gray-900 md:text-4xl">{blog.title}</h1>
                  <p className="mt-6 text-md text-gray-700">By: <span className="text-black">{blog.author}</span></p>
                  <div className="flex justify-center w-full pr-2 py-2 gap-1 md:gap-2 text-sm text-white md:text-md text-center items-center ">
                    <Flex gap="4px 0" wrap>
                    {blog.tags.split(",").map((tag, i) => {
                            return (
                                <Tag key={i} color="purple">{tag}</Tag>
                                )
                            })}
                        
                    </Flex>
                  </div>
                  <img className="mt-10 h-[30rem] w-full object-cover" src={blog.cover} alt={blog.title} />
                </header>

                <div className="mx-auto max-w-screen-lg space-y-12 rounded-b-lg bg-white px-8 pt-10 pb-20 font-serif text-md tracking-wide text-gray-700 sm:shadow-lg">
                  {htmlToReactParser.parse(blog.content)}
                </div>
                { error === true ?? (
                  <>
                  <div className="mx-auto max-w-screen-lg space-y-12 rounded-b-lg bg-white px-8 pt-10 pb-20 font-serif text-md tracking-wide text-gray-700 sm:shadow-lg">
                    <p>Unable to retrieve blog details at the moment</p>
                </div>
                  </>
                )}
              </>
            )}
          </article>
        </main>
      </div>
    </>
  );
}
export default BlogDetail;